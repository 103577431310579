import { Subject } from 'rxjs';

import { Injectable, OnInit } from '@angular/core';

@Injectable({ providedIn: 'root' })
/**
 * service for show/hide loader
 *
 * @export
 * @class LoaderService
 */
export class LoaderService implements OnInit {
  loader = new Subject<boolean>();
  private count = 0;
  onLoaderStatusChanged = this.loader.asObservable();
  /**
   * Creates an instance of LoaderService.
   * @memberof LoaderService
   */
  constructor() { }

  ngOnInit() { }

  showLoader() {
    this.count++;
    // const isLoaderValueFalse = this.loader.value === false;
    // if (isLoaderValueFalse) {
    this.loader.next(true);
    //   // console.time('api call');
    // }
  }

  hideLoader() {
    this.count--;
    // const isLoaderValueTrue = this.loader.value === true;
    if (this.count <= 0) {
      this.loader.next(false);
      this.count = 0;
    }
  }
}
