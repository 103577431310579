<div class="flex flex-col relative">
  <label [for]="label" *ngIf="!hideLabel" class="font-medium">{{label}}</label>
    <mat-form-field class="example-chip-list" appearance="fill">
        <mat-chip-list #chipList aria-label="Chip Control selection">
            <mat-chip *ngFor="let data of selectedList;let i = index" (removed)="remove(i)">
                {{data.label}}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <input [placeholder]="label" #fruitInput [formControl]="inputCtrl" [matAutocomplete]="auto"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)" >
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let data of filteredList" [value]="data">
                {{data.label}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
