<div class="sidebar-container">
  <mat-toolbar class="flex" color="primary">
    <span>CDSS</span>
    <div class="flex-1"></div>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <!-- <button mat-menu-item>
                <mat-icon>account_circle</mat-icon>
                <span>Profile</span>
            </button> -->
      <button mat-menu-item [routerLink]="[ '/user/change-password']">
        <mat-icon>lock</mat-icon>
        <span>Change Password</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>login</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </mat-toolbar>
  <app-menu-list></app-menu-list>
</div>