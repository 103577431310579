import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { AccessType } from 'src/app/core/enums/access-type.enum';
import { ApiResponse } from 'src/app/core/interfaces/api-response.interface';
import { TableButton, TableColumn } from '../../model/table.model';

@Component({
  selector: 'app-search-table',
  templateUrl: './search-table.component.html',
  styleUrls: ['./search-table.component.scss'],
})
export class SearchTableComponent extends BaseComponent implements OnInit {
  @Input() title = '';
  @Input() accessName = '';
  @Input() accessTypeCode!: AccessType;
  @Input() list: any[] = [];
  @Input() hideSearch = false;
  @Input() isNumber = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() displayColumns: TableColumn[] = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Short/Nick Name',
      key: 'code',
    }
  ];
  @Input() buttons: TableButton[] = [
    {
      color: 'primary',
      icon: 'view',
      name: 'view',
    },
  ];
  optionList: any[] = [];

  searchObs$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(injector: Injector, private ref: ChangeDetectorRef) {
    super(injector);
  }

  ngOnInit(): void {
    this.searchObs$.pipe().subscribe((searchText) => {
      //Note(dk): if hideSearch false means no need to get default search list
      if (!this.hideSearch) {
        this.getTestList(searchText);
      }
    });
  }

  /**
   * Find Disease
   * @param $searchText
   */
  findTest(searchText: string) {
    this.searchObs$.next(searchText);
  }

  async onOptionSelected(event: any): Promise<void> {
    try {
      const isExists = this.list.filter((data) => data.code === event);

      if (isExists.length > 0) return;

      const response: ApiResponse<[any[], number]> =
        await this.httpService.httpRequest<[any[], number]>(
          this.accessName,
          this.getActionString(1700, 'find-one-by-admin', 'find-one'),
          {
            where: event?.trim()
              ? {
                name: event,
              }
              : {},
          }
        );
      if (response.status) {
        this.list = [...this.list, response.data];
        this.updateValue();
        this.ref.markForCheck();
      }
    } catch (e) {
      this.appCatchError(e, 'getTestList');
    }
  }

  /**
   * On action performed in table
   * @param buttomAction
   */
  onAction(buttomAction: any) {
    this.list = this.list.filter(
      (disease) => disease.id != buttomAction.data.id
    );
    this.updateValue();
  }

  async getTestList(searchText: string): Promise<void> {
    const payload = {
      limit: 10,
      offset: 0,
      take: 10,
      skip: 0,
      where: searchText?.trim()
        ? {
          name: searchText,
        }
        : {},
    };

    try {
      const response: ApiResponse<[any[], number]> =
        await this.httpService.httpRequest<[any[], number]>(
          this.accessName,
          this.getActionString(
            this.accessType,
            'find-all-and-count-by-admin',
            'find-all-and-count'
          ),
          payload
        );
      if (response.status) {
        const [list] = response.data ?? [[], 0];
        this.optionList = list.map((data) => ({
          label: `${data.name} (${data.code})`,
          value: data.code,
        }));
      }
    } catch (e) {
      this.appCatchError(e, 'getTestList');
    }
  }

  updateValue() {
    const testIds = this.list.map((data) =>
      this.isNumber ? data.id : { id: data.id }
    );
    this.onChange.emit(testIds);
  }
}
