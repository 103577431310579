import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-date-form-control',
  templateUrl: './date-form-control.component.html',
  styleUrls: ['./date-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFormControlComponent),
      multi: true,
    },
  ],
})
export class DateFormControlComponent implements OnInit {
  @Input() label: string = '';
  @Input() hasError: boolean | undefined = false;
  @Input() showSuffixButton = false;
  @Input() required = false;
  @Input() maxValue: Date | null = null;
  @Output() onChange = new EventEmitter<string>();
  @Output() onButtonClick = new EventEmitter<string>();
  searchText = '';
  value: any;
  propagateChange = (_: any) => {};
  touchChange = (_: any) => {};

  constructor() {}

  ngOnInit(): void {}

  writeValue(obj: any): void {
    if (obj === undefined) return;

    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchChange = fn;
  }

  onChangeValue(): void {
    // this.onChange.emit(this.value);
    this.propagateChange(this.value);
  }
}
