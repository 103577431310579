import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { ApiResponse } from '../interfaces/api-response.interface';
import { AppHttpService } from './app-http.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  currentId: number | undefined;
  constructor(private httpService: AppHttpService) {}

  checkName(
    nameString: string,
    accessName: string,
    isUpdate = false
  ): Promise<any> {
    const condition: { where: CheckNameCondition } = {
      where: { name: nameString.trim() },
    };
    if (isUpdate) condition.where.id = this.currentId;
    return this.httpService.httpRequest<boolean>(
      accessName,
      'is-exist-by-admin',
      condition,
      { showLoader: false }
    );
  }
}

export interface CheckNameCondition {
  id?: number;
  name: string;
}
