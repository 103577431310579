export interface Message {
    title: string;
    text: string;
    show: boolean;
    type: MessageType;
    dismissible?: boolean;
}

export enum MessageType {
    // eslint-disable-next-line no-unused-vars
    SUCCESS = 'success',
    // eslint-disable-next-line no-unused-vars
    DANGER = 'danger',
    // eslint-disable-next-line no-unused-vars
    WARNING = 'warning',
    // eslint-disable-next-line no-unused-vars
    INFO = 'info',
    // eslint-disable-next-line no-unused-vars
    NONE = ''
}
