<div class="table-container cdss-table">
    <table class="w-full" mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef> S.No </th>
            <td mat-cell *matCellDef="let row; let i = index"> {{i + (currentPage * perPage) + 1}} </td>
        </ng-container>

        <ng-container *ngFor="let col of columns" [matColumnDef]="col.key">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> {{col.label}} </th>
            <td class="text-center" mat-cell *matCellDef="let row"> {{row[col.key]}}
            </td>
        </ng-container>

        <ng-container matColumnDef="action" *ngIf="buttons.length || showCheck">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let row;let i = index">
                <div class="flex gap-2 justify-c items-center">
                    <mat-checkbox *ngIf="showCheck" [(ngModel)]="dataList[i].checked" color="primary"
                        (ngModelChange)="onChangeCheck()">
                    </mat-checkbox>
                    <button *ngFor="let button of buttons" type="button" mat-icon-button [color]="button.color" [auth]="button.access!"
                    (click)="onClickAction(button.name, row, i)" ngClass="">
                    <mat-icon>{{button.icon}}</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns" class="bg-secondary"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"
            [ngStyle]="{'backgroundColor': row.rowColor ? row.rowColor : 'inherit' }"></tr>
    </table>
    <div class="text-center font-medium my-3" *ngIf="!dataList?.length">
        No Data Found
    </div>
    <div *ngIf="!hidePagination">
        <mat-paginator [length]="totalRecords" [pageSizeOptions]="pageSizeOptions" [pageSize]="perPage"
            showFirstLastButtons [pageIndex]="currentPage" (page)="onPageChange($event)"
            aria-label="Select page of user list">
        </mat-paginator>
    </div>
</div>
