/* eslint-disable linebreak-style */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * App service
 *
 * @export
 * @class AppService
 */
export class AppService {
  countries: any;
  domains: any;
  /**
   * Creates an instance of AppService.
   * @memberof AppService
   */
  constructor(private http: HttpClient) {}

  /**
   * Get country list
   *
   * @memberof AppService
   */
  async getCountryList(): Promise<string[][]> {
    if (!this.countries) {
      this.countries = await lastValueFrom(
        this.http.get('assets/json/countries.json')
      );
    }
    return this.countries;
  }

  /**
   * Get domain list
   *
   * @return {*}  {Promise<any>}
   * @memberof AppService
   */
  async getDomainList(): Promise<any> {
    if (!this.domains) {
      this.domains = await this.http
        .get('assets/json/domains.json')
        .toPromise();
    }
    return this.domains;
  }

  /**
   * get years list
   *
   * @param {string} type
   * @param {number} [count=5]
   * @return {*}  {number[]}
   * @memberof AppService
   */
  getYears(): number[] {
    const arrayLength = new Date().getFullYear() - 1999;
    return Array(arrayLength)
      .fill(0)
      .map((data, index) => 2000 + index);
  }

  /**
   * compare value  for sorting
   *
   * @protected
   * @param {(number | string)} a
   * @param {(number | string)} b
   * @param {boolean} isAsc
   * @return {*}
   * @memberof AppService
   */
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
