import { InputType } from 'src/app/shared/enums/input-type.enum';

import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-text-view-control',
  templateUrl: './text-view-control.component.html',
  styleUrls: ['./text-view-control.component.scss'],
})
export class TextViewControlComponent implements OnInit {
  @Input() label: string = '';
  @Input() showLabel = true;
  @Input() isBold = false;
  @Input() isPrimary = false;

  @Input() type: InputType = InputType.Text;
  @Input() value?: string | number | string[] | number[] = '';

  setValue(val: string | number | string[] | number[]) {
    if (Array.isArray(val)) {
      return val.join(', ');
    }
    return val;
  }

  constructor() {}

  ngOnInit(): void {}
}
