import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { Message, MessageType } from '../models/message.model';
import { ToasterType } from '../models/toaster-type.enum';

@Injectable({
  providedIn: 'root',
})

/**
 * Service for handle and show toaster messages
 *
 * @export
 * @class notificationService
 */
export class MessageService {
  messageData$: BehaviorSubject<Message> = new BehaviorSubject<Message>({
    title: '',
    text: '',
    show: false,
    type: MessageType.NONE,
  });
  /**
   * Creates an instance of notificationService.
   * @param {ToastrService} toasterService
   * @memberof notificationService
   */
  constructor(private toasterService: ToastrService) {}

  /**
   * To reset toaster message
   *
   * @memberof notificationService
   */
  resetMessage() {
    this.messageData$.next({
      show: false,
      title: '',
      text: '',
      type: MessageType.NONE,
    });
  }

  /**
   * To show toaster messages
   *
   * @param {ToasterType} type type of message
   * @param {string} title toaster title
   * @param {string} message toaster message
   * @param {ToasterOption} [option] toaster extra options
   * @memberof notificationService
   */
  showToaster(
    type: ToasterType,
    title: string,
    message: string,
    option?: ToasterOption
  ) {
    switch (type) {
      case ToasterType.SUCCESS:
        this.toasterService.success(message, title, {});
        break;
      case ToasterType.ERROR:
        this.toasterService.error(message, title, {});
        break;
      case ToasterType.INFO:
        this.toasterService.info(message, title, {});
        break;
      case ToasterType.WARNING:
        this.toasterService.warning(message, title, {});
        break;
    }
  }

  /**
   * Show success toaster
   *
   * @param {string} message
   * @param {'Success'} title
   * @param {Partial<IndividualConfig>} option
   * @memberof notificationService
   */
  successToaster(
    message: string,
    title = 'Success',
    option?: Partial<IndividualConfig>
  ) {
    console.log(message);

    this.toasterService.success(message, title, option);
  }
  /**
   * Show error toaster
   *
   * @param {string} message
   * @param {'Success'} title
   * @param {Partial<IndividualConfig>} option
   * @memberof notificationService
   */
  errorToaster(
    message: string,
    title = 'Failed',
    option?: Partial<IndividualConfig>
  ) {
    this.toasterService.error(message, title, option);
  }
  /**
   * Show warning toaster
   *
   * @param {string} message
   * @param {'Success'} title
   * @param {Partial<IndividualConfig>} option
   * @memberof notificationService
   */
  warningToaster(
    message: string,
    title = 'Warning',
    option?: Partial<IndividualConfig>
  ) {
    this.toasterService.warning(message, title, option);
  }
  /**
   * Show info toaster
   *
   * @param {string} message
   * @param {'Success'} title
   * @param {Partial<IndividualConfig>} option
   * @memberof notificationService
   */
  infoToaster(
    message: string,
    title = 'Information',
    option?: Partial<IndividualConfig>
  ) {
    this.toasterService.info(message, title, option);
  }

  /**
   * Play notification sound
   */
  playNotificationSound() {
    const audio = new Audio('/assets/sounds/notification.mp3');
    audio.play();
  }
}

export interface ToasterOption {
  positionClass: string;
  timeOut: number;
  preventDuplicates: boolean;
  closeButton: boolean;
}
