/* eslint-disable linebreak-style */
import { ComponentType } from '@angular/cdk/portal';
import { Component, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { AddRoleComponent } from 'src/app/role/components/add-role/add-role.component';
import { BaseComponent } from '../components/base/base.component';
import { ConfirmComponent } from '../components/confirm/confirm.component';
@Injectable({
  providedIn: 'root',
})

/**
 * Modal service
 *
 * @export
 * @class ModalService
 */
export class ModalService {
  /**
   * Creates an instance of ModalService.
   * @memberof ModalService
   */
  constructor(public dialog: MatDialog) {}

  async openDialog(component: ComponentType<any>, data: any): Promise<any> {
    const dialogRef = this.dialog.open(component, {
      data,
      disableClose: true
    });
    return await lastValueFrom(dialogRef.afterClosed());
  }

  /**
   * Open confirm modal
   * @param component
   * @param data
   * @returns
   */
  async openConfirmDialog(data: any): Promise<any> {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data,
    });
    return await lastValueFrom(dialogRef.afterClosed());
  }

  async addRoleDialog(title: string, text: string, buttons: any[]) {
    const dialogRef = this.dialog.open(AppComponent, {
      data: {
        title,
        text,
        buttons,
      },
    });
    return await lastValueFrom(dialogRef.afterClosed());
  }
}
