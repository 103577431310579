import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message } from 'src/app/core/models/message.model';
import { MessageService } from 'src/app/core/services/message-service.service';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})

/**
 * Component for show dismissible message
 *
 * @export
 * @class MessageComponent
 * @implements {OnInit}
 */
export class MessageComponent implements OnInit, OnDestroy {
  @Input() message?: Message;
  subscription = new Subscription();
  /**
   * Creates an instance of MessageComponent.
   * @param {NotificationService} messageService
   * @memberof MessageComponent
   */
  constructor(private messageService: MessageService) {}
  /**
   *
   *
   * @memberof MessageComponent
   */
  ngOnInit(): void {
    this.subscription.add(
      this.messageService.messageData$.subscribe((data: any) => {
        this.message = data;
      })
    );
  }

  /**
   * FUnction for dismiss message
   *
   * @memberof MessageComponent
   */
  dismiss(): void {
    this.messageService.resetMessage();
  }

  /**
   * Unsubscribe subscription
   *
   * @memberof MessageComponent
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
