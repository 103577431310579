import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/services/auth.guard';
import { UserResolver } from './core/services/user-resolver.service';
import { LayoutComponent } from './layout/layout.component';
import { PriorityType } from './priority/enum/Priority-status.enum';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    resolve: { user: UserResolver },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'org',
        loadChildren: () =>
          import('./organization/organization.module').then((m) => m.OrganizationModule),
      },
      {
        path: 'role',
        loadChildren: () =>
          import('./role/role.module').then((m) => m.RoleModule),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'test-group',
        loadChildren: () =>
          import('./group/group.module').then((m) => m.GroupModule),
      },
      {
        path: 'test-category',
        loadChildren: () =>
          import('./category/category.module').then((m) => m.CategoryModule),
      },
      {
        path: 'test-profile',
        loadChildren: () =>
          import('./test-profile/test-profile.module').then((m) => m.TestProfileModule),
      },
      {
        path: 'disease',
        loadChildren: () =>
          import('./disease/disease.module').then((m) => m.DiseaseModule),
      },
      {
        path: 'disease-rule',
        loadChildren: () =>
          import('./disease-rule/disease-rule.module').then(
            (m) => m.DiseaseRuleModule
          ),
      },
      {
        path: 'symptom',
        loadChildren: () =>
          import('./symptom/symptom.module').then((m) => m.SymptomModule),
      },
      {
        path: 'cdss-test',
        loadChildren: () =>
          import('./test/test.module').then((m) => m.TestModule),
      },
      {
        path: 'test-rule',
        loadChildren: () =>
          import('./test-rule/test-rule.module').then((m) => m.TestRuleModule),
      },
      {
        path: 'test-rule-suggestion',
        loadChildren: () =>
          import('./test-rule-suggestion/test-rule-suggestion.module').then(
            (m) => m.TestRuleSuggestionModule
          ),
      },
      {
        path: 'priority-inclusion',
        data: {
          type: PriorityType.INCLUSION,
        },
        loadChildren: () =>
          import('./priority/priority.module').then((m) => m.PriorityModule),
      },
      {
        path: 'priority-exclusion',
        data: {
          type: PriorityType.EXCLUSION,
        },
        loadChildren: () =>
          import('./priority/priority.module').then((m) => m.PriorityModule),
      },
      {
        path: 'test-property',
        loadChildren: () =>
          import('./test-property/test-property.module').then(
            (m) => m.TestPropertyModule
          ),
      },
      {
        path: 'cdss-test-unit',
        loadChildren: () =>
          import('./test-unit/test-unit.module').then(
            (m) => m.CdssTestUnitModule
          ),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'laboratory',
        loadChildren: () =>
          import('./laboratory/laboratory.module').then((m) => m.LaboratoryModule),
      },
      {
        path: 'laboratory-branch',
        loadChildren: () =>
          import('./laboratory-branch/laboratory-branch.module').then((m) => m.LaboratoryBranchModule),
      },
      {
        path: 'ai-prompt',
        loadChildren: () =>
          import('./ai-prompt/ai-prompt.module').then((m) => m.AiPromptModule),
      },
      {
        path: 'feature',
        loadChildren: () =>
          import('./features/features.module').then((m) => m.FeaturesModule),
      },
      {
        path: 'org-feature',
        loadChildren: () =>
          import('./org-features/org-features.module').then((m) => m.OrgFeaturesModule),
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
