import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ModalComponent } from '../core/components/modal/modal.component';
import { ConditionComponent } from './components/condition-table/condition-table.component';
import { EditableComponent } from './components/editable-table/editable-table.component';
import { ChipFormControlComponent } from './components/form-controls/chip-form-control/chip-form-control.component';
import { DateFormControlComponent } from './components/form-controls/date-form-control/date-form-control.component';
import { SelectFormControlComponent } from './components/form-controls/select-form-control/select-form-control.component';
import { TextFormControlComponent } from './components/form-controls/text-form-control/text-form-control.component';
import { TextViewControlComponent } from './components/form-controls/text-view-control/text-view-control.component';
import { TextareaFormControlComponent } from './components/form-controls/textarea-form-control/textarea-form-control.component';
import { SearchTableComponent } from './components/search-table/search-table.component';
import { TableComponent } from './components/table/table.components';
import { MaterialSharedModule } from './material-shared.module';
import { AuthorizationDirective } from './directives/authorization.directive';
import { TruncatePipe } from './pipes/truncate.pipe';


@NgModule({
  declarations: [
    TextFormControlComponent,
    SelectFormControlComponent,
    DateFormControlComponent,
    ChipFormControlComponent,
    TextareaFormControlComponent,
    TextViewControlComponent,
    TableComponent,
    ModalComponent,
    SearchTableComponent,
    EditableComponent,
    ConditionComponent,
    AuthorizationDirective,
    TruncatePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialSharedModule,
  ],
  exports: [
    MaterialSharedModule,
    TextFormControlComponent,
    SelectFormControlComponent,
    ChipFormControlComponent,
    DateFormControlComponent,
    TextareaFormControlComponent,
    ConditionComponent,
    EditableComponent,
    TextViewControlComponent,
    TableComponent,
    ModalComponent,
    SearchTableComponent,
    AuthorizationDirective,
    TruncatePipe
  ],
})
export class SharedModule { }
