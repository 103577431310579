import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';

import { ToasterType } from '../models/toaster-type.enum';
import { MessageService } from './message-service.service';

@Injectable({
  providedIn: 'root',
})

/**
 *
 *
 * @export
 * @class LogService
 */
export class LoggerService {
  /**
   * Creates an instance of LogService.
   * @param {NotificationService} notificationService
   * @memberof LogService
   */
  constructor(private notificationService: MessageService) {}

  /**
   * Show exception via toaster and record exception log
   *
   * @param {string} error
   * @param {number} errorCode
   * @param {*} [exception]
   * @memberof LogService
   */
  exception(error: string, errorCode: number, exception?: any): void {
    this.notificationService.showToaster(
      ToasterType.ERROR,
      'Exception Failed!',
      environment.production ? '' : error + '. Error code' + errorCode
    );
  }

  /**
   * Error log
   *
   * @param {string} errorMessage
   * @param {string} componentName
   * @param {string} functionName
   * @memberof LoggerService
   */
  error(errorMessage: string, params?: string) {
    console.error(JSON.stringify(errorMessage), JSON.stringify(params));
  }

  /**
   * Warning log
   *
   * @param {string} errorMessage
   * @param {string} componentName
   * @param {string} functionName
   * @memberof LoggerService
   */
  warning(errorMessage: string, componentName: string, functionName: string) {
    console.warn(errorMessage);
  }
  /**
   * Info log
   *
   * @param {string} errorMessage
   * @param {string} componentName
   * @param {string} functionName
   * @memberof LoggerService
   */
  info(errorMessage: string, componentName?: string, functionName?: string) {
    console.info(errorMessage);
  }
}
