import { AccessType } from '../enums/access-type.enum';
import { AgeGroup } from '../enums/age-group.enum';
import { RoleAccess } from '../enums/role-access.enum';
import { ADD, ADMIN_DELETE, ADMIN_EDIT, ADMIN_SINGLE_VIEW, EDIT, FIND_ALL_DELETED, LIST_VIEW, RESTORE_DELETED_ONE, SINGLE_VIEW } from './access.const';

export const NAME_VALIDATION: RegExp = /^[a-zA-Z0-9-+\s\/\\_]*$/;
export const UNIT_NAME_VALIDATION: RegExp = /^[\s\S]*$/;
export const GENDER_LIST = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
];

export const GENDER_OPTIONS = [
  {
    label: 'Both',
    value: 'BOTH',
  },
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
];

export const DEFAULT_BUTTON = {
  color: 'primary',
  label: 'Close',
  value: 'close',
};

export const MENU_LIST = [
  {
    label: 'Dashboard',
    link: '',
    icon: 'dashboard',
    hidden: false,
    access: 105,
    subMenu: [],
  },
  {
    label: 'Organizations',
    link: 'org',
    icon: 'corporate_fare',
    hidden: false,
    access: [AccessType.Organization + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Organization',
        link: 'org/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.Organization+ADD],
      },
      {
        label: 'View Organization',
        link: 'org/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.Organization + SINGLE_VIEW, AccessType.Organization + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Organization',
        link: 'org/update',
        icon: 'edit',
        hidden: true,
        access: [AccessType.Organization + EDIT, AccessType.Organization + ADMIN_EDIT],
      },
      {
        label: 'Deleted Organization',
        link: 'org/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.Organization + FIND_ALL_DELETED, AccessType.Organization + RESTORE_DELETED_ONE],
      }
    ],
  },
  {
    label: 'User',
    link: 'user',
    icon: 'person',
    hidden: true,
    access: [AccessType.User + LIST_VIEW],
    subMenu: [
      {
        label: 'Add User',
        link: 'user/add',
        icon: 'person_add',
        hidden: true,
        access: [AccessType.User + ADD],
      },
    ],
  },
  {
    label: 'Role',
    link: 'role',
    icon: 'supervisor_account',
    hidden: true,
    access: [AccessType.Role + LIST_VIEW],
    subMenu: [
      {
        label: 'Access',
        link: 'role/access',
        icon: 'group',
        hidden: true,
        access: 905,
      },
    ],
  },
  {
    label: 'Laboratory',
    link: 'laboratory',
    icon: 'science',
    hidden: false,
    access: [AccessType.Lab + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Laboratory',
        link: 'laboratory/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.Lab+ADD],
      },
      {
        label: 'View Laboratory',
        link: 'laboratory/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.Lab + SINGLE_VIEW, AccessType.Lab + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Laboratory',
        link: 'laboratory/update',
        icon: 'edit',
        hidden: true,
        access: [AccessType.Lab + EDIT, AccessType.Lab + ADMIN_EDIT],
      },
      {
        label: 'Deleted Laboratory',
        link: 'laboratory/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.Lab + FIND_ALL_DELETED, AccessType.Lab + RESTORE_DELETED_ONE],
      }
    ],
  },
  {
    label: 'Laboratory Branch',
    link: 'laboratory-branch',
    icon: 'add_business',
    hidden: false,
    access: [AccessType.LabBranch + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Branch',
        link: 'laboratory-branch/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.LabBranch + ADD],
      },
      {
        label: 'View Branch',
        link: 'laboratory-branch/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.LabBranch + SINGLE_VIEW, AccessType.Lab + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Branch',
        link: 'laboratory-branch/update',
        icon: 'edit',
        hidden: true,
        access: [AccessType.LabBranch + EDIT, AccessType.Lab + ADMIN_EDIT],
      },
      {
        label: 'Deleted Branch',
        link: 'laboratory-branch/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.LabBranch + RESTORE_DELETED_ONE, AccessType.Lab + FIND_ALL_DELETED],
      }
    ],
  },
  {
    label: 'Group',
    link: 'test-group',
    icon: 'diversity_1',
    hidden: false,
    access: [AccessType.TestGroup + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Group',
        link: 'test-group/add',
        icon: 'add',
        hidden: true,
        access: AccessType.TestGroup + ADD,
      },
      {
        label: 'View Group',
        link: 'test-group/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.TestGroup + SINGLE_VIEW, AccessType.TestGroup + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Group',
        link: 'test-group/update',
        icon: 'edit',
        hidden: true,
        access: [AccessType.TestGroup + EDIT, AccessType.TestGroup + ADMIN_EDIT],
      },
      {
        label: 'Deleted Group',
        link: 'test-group/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.TestGroup + FIND_ALL_DELETED, AccessType.Lab + RESTORE_DELETED_ONE],
      }
    ],
  },
  {
    label: 'Category',
    link: 'test-category',
    icon: 'diversity_3',
    hidden: false,
    access: [AccessType.TestCategory + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Category',
        link: 'test-category/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.TestCategory + ADD],
      },
      {
        label: 'View Category',
        link: 'test-category/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.TestGroup + SINGLE_VIEW, AccessType.TestGroup + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Category',
        link: 'test-category/update',
        icon: 'edit_square',
        hidden: true,
        access: [AccessType.TestGroup + EDIT, AccessType.TestGroup + ADMIN_EDIT],
      },
      {
        label: 'Deleted Category',
        link: 'test-category/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.TestGroup + FIND_ALL_DELETED, AccessType.TestGroup + RESTORE_DELETED_ONE],
      },
    ],
  },
  {
    label: 'Profile',
    link: 'test-profile',
    icon: 'dataset',
    hidden: false,
    access: [AccessType.TestProfile + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Profile',
        link: 'test-profile/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.TestProfile + ADD],
      },
      {
        label: 'View Profile',
        link: 'test-profile/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.TestProfile + SINGLE_VIEW, AccessType.TestProfile + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Profile',
        link: 'test-profile/update',
        icon: 'edit',
        hidden: true,
        access: [AccessType.TestProfile + EDIT, AccessType.TestProfile + ADMIN_EDIT],
      },
      {
        label: 'Deleted Profile',
        link: 'test-profile/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.TestProfile + FIND_ALL_DELETED, AccessType.TestProfile + RESTORE_DELETED_ONE],
      }
    ],
  },
  {
    label: 'Test',
    link: 'cdss-test',
    icon: 'wysiwyg',
    hidden: false,
    access: [AccessType.Test + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Test',
        link: 'cdss-test/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.Test + ADD],
      },
      {
        label: 'View Test',
        link: 'cdss-test/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.Test + SINGLE_VIEW, AccessType.Test + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Test',
        link: 'cdss-test/update',
        icon: 'edit_square',
        hidden: true,
        access: [AccessType.Test + EDIT, AccessType.Test + ADMIN_EDIT],
      },
      {
        label: 'Deleted Test',
        link: 'cdss-test/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.Test + FIND_ALL_DELETED, AccessType.Test + RESTORE_DELETED_ONE],
      },
    ],
  },
  {
    label: 'Disease',
    link: 'disease',
    icon: 'face_retouching_natural',
    hidden: false,
    access: [AccessType.Disease + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Disease',
        link: 'disease/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.Disease + ADD],
      },
      {
        label: 'View Disease',
        link: 'disease/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.Disease + SINGLE_VIEW, AccessType.Lab + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Disease',
        link: 'disease/update',
        icon: 'edit_square',
        hidden: true,
        access: [AccessType.Disease + EDIT, AccessType.Disease + ADMIN_EDIT],
      },
      {
        label: 'Deleted Disease',
        link: 'disease/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.Disease + FIND_ALL_DELETED, AccessType.Disease + RESTORE_DELETED_ONE],
      },
    ],
  },

  {
    label: 'Symptoms',
    link: 'symptom',
    icon: 'self_improvement',
    hidden: true,
    access: [AccessType.Symptom + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Symptom',
        link: 'symptom/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.Symptom + ADD],
      },
      {
        label: 'View Symptom',
        link: 'symptom/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.Symptom + SINGLE_VIEW, AccessType.Symptom + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Symptom',
        link: 'symptom/update',
        icon: 'edit_square',
        hidden: true,
        access: [AccessType.Symptom + EDIT, AccessType.Symptom + ADMIN_EDIT],
      },
      {
        label: 'Deleted Symptom',
        link: 'symptom/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.Symptom + FIND_ALL_DELETED, AccessType.Symptom + RESTORE_DELETED_ONE],
      },
    ],
  },
  {
    label: 'Test to Disease Link',
    link: 'test-rule',
    icon: 'medical_information',
    hidden: false,
    access: [AccessType.TestRule + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Test Rule',
        link: 'test-rule/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.TestRule + ADD],
      },
      {
        label: 'View Test Rule',
        link: 'test-rule/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.TestRule + SINGLE_VIEW, AccessType.TestRule + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Test Rule',
        link: 'test-rule/update',
        icon: 'edit_square',
        hidden: true,
        access: [AccessType.TestRule + EDIT, AccessType.TestRule + ADMIN_EDIT],
      },
      {
        label: 'Deleted Test Rule',
        link: 'test-rule/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.TestRule + FIND_ALL_DELETED, AccessType.TestRule + RESTORE_DELETED_ONE],
      },
    ],
  },
  {
    label: 'Disease To Symptom Link',
    link: 'disease-rule',
    icon: 'face_retouching_natural',
    hidden: false,
    access: [AccessType.DiseaseRule + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Disease To Symptom Link',
        link: 'disease-rule/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.DiseaseRule + ADD],
      },
      {
        label: 'View Disease To Symptom Link',
        link: 'disease-rule/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.DiseaseRule + SINGLE_VIEW, AccessType.DiseaseRule + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Disease To Symptom Link',
        link: 'disease-rule/update',
        icon: 'edit_square',
        hidden: true,
        access: [AccessType.DiseaseRule + EDIT, AccessType.DiseaseRule + ADMIN_EDIT],
      },
      {
        label: 'Deleted Disease To Symptom Link',
        link: 'disease-rule/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.DiseaseRule + FIND_ALL_DELETED, AccessType.DiseaseRule + RESTORE_DELETED_ONE],
      },
    ],
  },
  // {
  //   label: 'Test to Disease Link Suggestion',
  //   link: 'test-rule-suggestion',
  //   icon: 'edit_note',
  //   hidden: false,
  //   access: 905,
  //   subMenu: [
  //     {
  //       label: 'View Test Rule',
  //       link: 'test-rule-suggestion/view',
  //       icon: 'visibility',
  //       hidden: true,
  //       access: 905,
  //     },
  //     {
  //       label: 'Add Test Rule',
  //       link: 'test-rule-suggestion/add',
  //       icon: 'add',
  //       hidden: true,
  //       access: 905,
  //     },
  //     {
  //       label: 'Update Test Rule',
  //       link: 'test-rule-suggestion/update',
  //       icon: 'edit_square',
  //       hidden: true,
  //       access: 905,
  //     },
  //   ],
  // },

  {
    label: 'Priority Inclusion',
    link: 'priority-inclusion',
    icon: 'download',
    hidden: false,
    access: [AccessType.Priority + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Inclusion',
        link: 'priority-inclusion/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.Priority + ADD],
      },
      {
        label: 'View Inclusion',
        link: 'priority-inclusion/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.Priority + SINGLE_VIEW, AccessType.Priority + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Inclusion',
        link: 'priority-inclusion/update',
        icon: 'edit_square',
        hidden: true,
        access: [AccessType.Priority + EDIT, AccessType.Priority + ADMIN_EDIT],
      },
      {
        label: 'Deleted Inclusion',
        link: 'priority-inclusion/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.Priority + FIND_ALL_DELETED, AccessType.Priority + RESTORE_DELETED_ONE],
      },
    ],
  },
  {
    label: 'Priority Exclusion',
    link: 'priority-exclusion',
    icon: 'publish',
    hidden: false,
    access: [AccessType.Priority + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Exclusion',
        link: 'priority-exclusion/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.Priority + ADD],
      },
      {
        label: 'View Exclusion',
        link: 'priority-exclusion/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.Priority + SINGLE_VIEW, AccessType.Priority + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Exclusion',
        link: 'priority-exclusion/update',
        icon: 'edit_square',
        hidden: true,
        access: [AccessType.Priority + EDIT, AccessType.Priority + ADMIN_EDIT],
      },
      {
        label: 'Deleted Exclusion',
        link: 'priority-exclusion/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.Priority + FIND_ALL_DELETED, AccessType.Priority + RESTORE_DELETED_ONE],
      },
    ],
  },
  // {
  //   label: 'Test Property',
  //   link: 'test-property',
  //   icon: 'bookmarks',
  //   hidden: false,
  //   access: 905,
  //   subMenu: [
  //     {
  //       label: 'Add',
  //       link: 'test-property/add',
  //       icon: 'add',
  //       hidden: true,
  //       access: 905,
  //     },
  //     {
  //       label: 'View',
  //       link: 'test-property/view',
  //       icon: 'visibility',
  //       hidden: true,
  //       access: 906,
  //     },
  //     {
  //       label: 'Update',
  //       link: 'test-property/update',
  //       icon: 'edit',
  //       hidden: true,
  //       access: 907,
  //     },
  //   ],
  // },
  {
    label: 'Report',
    link: 'report',
    icon: 'report',
    hidden: true,
    access: [],
    subMenu: [
      {
        label: 'Add',
        link: 'report/add',
        icon: 'add',
        hidden: true,
        access: 105,
      },
    ],
  },
  {
    label: 'Test Unit',
    link: 'cdss-test-unit',
    icon: 'dataset_linked',
    hidden: false,
    access: [AccessType.CdssTestUnit + LIST_VIEW],
    subMenu: [
      {
        label: 'Add Unit',
        link: 'cdss-test-unit/add',
        icon: 'add',
        hidden: true,
        access: [AccessType.CdssTestUnit + ADD],
      },
      {
        label: 'View Unit',
        link: 'cdss-test-unit/view',
        icon: 'visibility',
        hidden: true,
        access: [AccessType.CdssTestUnit + SINGLE_VIEW, AccessType.CdssTestUnit + ADMIN_SINGLE_VIEW],
      },
      {
        label: 'Update Unit',
        link: 'cdss-test-unit/update',
        icon: 'edit',
        hidden: true,
        access: [AccessType.CdssTestUnit + EDIT, AccessType.CdssTestUnit + ADMIN_EDIT],
      },
      {
        label: 'Deleted Unit',
        link: 'cdss-test-unit/deleted',
        icon: 'delete',
        hidden: true,
        access: [AccessType.CdssTestUnit + FIND_ALL_DELETED, AccessType.CdssTestUnit + RESTORE_DELETED_ONE],
      },
    ],
  },
  {
    label: 'Ai Prompt',
    link: 'ai-prompt',
    icon: 'psychology',
    hidden: false,
    access: 905,
    subMenu: [
      {
        label: 'Add Prompt',
        link: 'ai-prompt/add',
        icon: 'add',
        hidden: true,
        access: 905,
      },
      {
        label: 'Update Prompt',
        link: 'ai-prompt/update',
        icon: 'visibility',
        hidden: true,
        access: 905,
      },
    ]
  },
  {
    label: 'Features',
    link: 'feature',
    icon: 'workspaces',
    hidden: false,
    access: 905,
    subMenu: [
      {
        label: 'Add Feature',
        link: 'feature/add',
        icon: 'add',
        hidden: true,
        access: 905,
      },
      {
        label: 'View Feature',
        link: 'feature/view',
        icon: 'visibility',
        hidden: true,
        access: 905,
      },
      {
        label: 'Update Feature',
        link: 'feature/update',
        icon: 'edit',
        hidden: true,
        access: 905,
      }
    ]
  },
  {
    label: 'Org Features',
    link: 'org-feature',
    icon: 'room_preferences',
    hidden: false,
    access: 905,
    subMenu: [
      {
        label: 'Add Org Feature',
        link: 'org-feature/add',
        icon: 'add',
        hidden: true,
        access: 905,
      },
      {
        label: 'View Org Feature',
        link: 'org-feature/view',
        icon: 'visibility',
        hidden: true,
        access: 905,
      },
      {
        label: 'Update Org Feature',
        link: 'org-feature/update',
        icon: 'edit',
        hidden: true,
        access: 905,
      }
    ]
  },

];

export const APP_CONST = {
  PER_PAGE: 10,
  PAGE_SIZE_OPTIONS: [10, 25, 50, 100],
  DEFAULT_COUNTRY: ['All Countries'],
  DEFAULT_AGE_GROUP: [AgeGroup.ALL],
  DEFAULT_GENDER: 'BOTH',
  REQUEST_METHOD_LIST: [
    {
      label: 'GET',
      value: 'get',
    },
    {
      label: 'POST',
      value: 'post',
    },
    {
      label: 'PUT',
      value: 'put',
    },
    {
      label: 'PATCH',
      value: 'patch',
    },
    {
      label: 'DELETE',
      value: 'delete',
    },
  ],
};

export const minMaxColumns = [
  {
    label: 'Unit',
    key: 'unit',
  },
  {
    label: 'Min',
    key: 'min',
  },
  {
    label: 'Max',
    key: 'max',
  },
];
