import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { firstValueFrom, of } from 'rxjs';
import { UserService } from './user.service';
@Injectable({ providedIn: 'root' })

/**
 * User resolver service
 *
 * @export
 * @class UserResolver
 * @implements {Resolve<ObjectToResolve>}
 */
export class UserResolver implements Resolve<any> {
  /**
   * Creates an instance of UserResolver.
   * @memberof UserResolver
   */
  constructor(private userService: UserService) {}
  /**
   * Resolve functin
   *
   * @param {ActivatedRouteSnapshot} route
   * @memberof UserResolver
   */
  async resolve(route: ActivatedRouteSnapshot): Promise<any> {
    await this.userService.getUserProfileDetails();
    return firstValueFrom(of(null));
  }
}
