import { Subscription } from 'rxjs/internal/Subscription';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})

/**
 * Component for show loader between API request
 *
 * @export
 * @class LoaderComponent
 * @implements {OnInit}
 */
export class LoaderComponent implements OnInit, OnDestroy {
  isLoaderShowing = false;
  @Input() loading = false;

  loaderUnSubscription!: Subscription;
  /**
   * Creates an instance of LoaderComponent.
   * @memberof LoaderComponent
   */
  constructor(private loaderServices: LoaderService) {}

  /**
   *
   *
   * @memberof LoaderComponent
   */
  ngOnInit() {
    this.loaderUnSubscription =
      this.loaderServices.onLoaderStatusChanged.subscribe((loaderStatus) => {
        this.isLoaderShowing = loaderStatus;
      });
  }

  /**
   *
   *
   * @memberof LoaderComponent
   */
  ngOnDestroy() {
    this.loaderUnSubscription.unsubscribe();
  }
}
