import { Subject, Subscription, debounceTime } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AccessService } from '../../services/access.service';
import { AppHttpService } from '../../services/app-http.service';
import { AppService } from '../../services/app.service';
import { LoaderService } from '../../services/loader.service';
import { LoggerService } from '../../services/log.service';
import { MessageService } from '../../services/message-service.service';
import { AccessType } from '../../enums/access-type.enum';
import { BaseService } from '../../services/base.service';
import { AccessString } from '../../enums/access-string.enum';
import { ActionType } from '../../enums/action-type.enum';
import { AccessPage } from '../../enums/access-page.enum';
import { PageAccess } from '../../models/page-access.model';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent {
  subscription = new Subscription();
  private componentName?: string;
  private loggerService?: LoggerService;

  protected httpService: AppHttpService;
  protected loaderService: LoaderService;
  protected appService: AppService;
  protected messageService: MessageService;
  protected accessService: AccessService;
  protected baseService: BaseService;
  protected router: Router;
  protected searchDebounceSubject = new Subject<any>();

  //For Authorization
  protected accessType!:AccessType;
  protected accessString!:AccessString;
  protected accessTypes = AccessType;
  protected pageAccess:PageAccess = {};


  public appBaseUrl = environment.endPointUrl;
  accessCode = AccessType;

  constructor(injector: Injector) {
    this.httpService = injector.get(AppHttpService);
    this.loggerService = injector.get(LoggerService);
    this.loaderService = injector.get(LoaderService);
    this.appService = injector.get(AppService);
    this.messageService = injector.get(MessageService);
    this.accessService = injector.get(AccessService);
    this.baseService = injector.get(BaseService);
    this.router = injector.get(Router);
    this.componentName = this.constructor.name;
  }

  /**
   * catch exceptions
   *
   * @param {*} exception
   * @param {string} [functionName='']
   * @param {*} [params]
   * @memberof BaseComponent
   */
  appCatchError(exception: any, functionName = '', params?: any) {
    console.warn(exception);
    this.loggerService?.error(
      `${this.componentName!}=>${functionName}=>
      ${JSON.stringify(exception)}`,
      JSON.stringify(params)
    );
  }

  trackBy(index: number, item: any) {
    return index;
  }

  getActionString(access: number | number[], str1: string, str2: string) {
    return this.accessService.checkAccess(access) ? str1 : str2;
  }

  /**
   * @param access : number | number[]
   * @param actionString : [string, string] It should contain 2 members-> [admin-action-string, default-action-string]
  */
  getActionString1(access: number | number[], actionString: [string, string]):string {
    return this.accessService.checkAccess(access) ? actionString[0] : actionString[1];
  }

  accessConfig(accessType: AccessType, accessString:AccessString, page: AccessPage){
    this.accessType = accessType;
    this.accessString = accessString;
    this.updatePageAccess(page);
  }

  private updatePageAccess(page: AccessPage) {
    switch (page) {
      case AccessPage.ADD:
        this.getAddAccess();
        break;

      case AccessPage.UPDATE:
        this.getEditAccess();
        break;

      case AccessPage.VIEW:
        this.getViewAccess();
        break;

      case AccessPage.DELETED_LIST:
        this.getDeletedAccess();
        break;

      case AccessPage.LIST:
        this.getListAccess();
        break;

      case AccessPage.OTHERS:
        break;

      default:
        break;
    }
  }

  getAddAccess(){
    this.pageAccess = {
      add: this.accessType + ActionType.ADD
    };
  }

  getEditAccess(){
    this.pageAccess = {
      edit: [this.accessType + ActionType.ADMIN_EDIT, this.accessType + ActionType.EDIT],
      view: [this.accessType + ActionType.ADMIN_SINGLE_VIEW, this.accessType + ActionType.SINGLE_VIEW],
      adminEdit: this.accessType + ActionType.ADMIN_EDIT,
      adminList: this.accessType + ActionType.ADMIN_FIND_ALL_LIST_VIEW,
      adminFindOne: this.accessType + ActionType.ADMIN_FIND_ONE_VIEW,
      adminView: this.accessType + ActionType.ADMIN_SINGLE_VIEW,
    };
  }

  getViewAccess(){
    this.pageAccess = {
      view: [this.accessType + ActionType.ADMIN_SINGLE_VIEW, this.accessType + ActionType.SINGLE_VIEW],
      edit: [this.accessType + ActionType.ADMIN_EDIT, this.accessType + ActionType.EDIT],
      delete: [this.accessType + ActionType.ADMIN_DELETE, this.accessType + ActionType.DELETE],
      adminView: this.accessType + ActionType.ADMIN_SINGLE_VIEW,
      adminList: this.accessType + ActionType.ADMIN_FIND_ALL_LIST_VIEW,
      adminFindOne: this.accessType + ActionType.ADMIN_FIND_ONE_VIEW,
      adminDelete: this.accessType + ActionType.ADMIN_DELETE
    };
  }

  getDeletedAccess(){
    this.pageAccess = {
      deleted: [this.accessType + ActionType.FIND_ALL_DELETED, this.accessType + ActionType.RESTORE_DELETED_ONE],
      findAllDeleted: this.accessType + ActionType.FIND_ALL_DELETED,
      restoreDeletedOne: this.accessType + ActionType.RESTORE_DELETED_ONE,
      deleteRecordPermanently: this.accessType + ActionType.DELETE_RECORD_PERMANENTLY
    };
  }

  getListAccess(){
    this.pageAccess = {
      add: this.accessType + ActionType.ADD,
      list: [this.accessType + ActionType.ADMIN_FIND_ALL_LIST_VIEW, this.accessType + ActionType.FIND_ALL_LIST_VIEW],
      view: [this.accessType + ActionType.ADMIN_SINGLE_VIEW, this.accessType + ActionType.SINGLE_VIEW],
      edit: [this.accessType + ActionType.ADMIN_EDIT, this.accessType + ActionType.EDIT],
      delete: [this.accessType + ActionType.ADMIN_DELETE, this.accessType + ActionType.DELETE],
      adminList: this.accessType + ActionType.ADMIN_FIND_ALL_LIST_VIEW,
      adminDelete:this.accessType + ActionType.ADMIN_DELETE,
    };
  }



  setupSearchDebounceCallback(callback: Function) {
    this.searchDebounceSubject
      .pipe(debounceTime(300)) // Adjust the debounce time (in milliseconds) as per your needs
      .subscribe((event) => {
        callback(event);
      });
  }


  /**
   * remove all variables
   *
   * @memberof BaseComponent
   */
  ngOnDestroy(): void {
    (this.httpService as any) = undefined;
    (this.loaderService as any) = undefined;
    this.componentName = undefined;
    this.loggerService = undefined;
    this.subscription.unsubscribe();
  }
}
