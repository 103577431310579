export enum RangeValuesEnum {
  ABNORMAL = 'ABNORMAL',
  NORMAL = 'NORMAL',
  ALL_MIN = 'ALL_MIN',
  ALL_MAX = 'ALL_MAX',
  MIN = 'MIN',
  MAX = 'MAX',
  MILD_MIN = 'MILD_MIN',
  MILD_MAX = 'MILD_MAX',
  CRITICAL_MIN = 'CRITICAL_MIN',
  CRITICAL_MAX = 'CRITICAL_MAX',
}

export enum CdssTestValueTypeEnum {
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  EQUAL = 'EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  RANGE = 'RANGE',
}
