import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, maxLength: number): string {
    if (!value) return ''; // Return empty string if value is falsy
    if (value.length <= maxLength) return value; // Return value if its length is less than or equal to maxLength

    // Truncate the value and add ellipsis
    return value.substring(0, maxLength - 3) + '...';
  }
}
