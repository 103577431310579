import { Component, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONST } from '../../constants/app.constant';
import { BaseComponent } from '../base/base.component';
import { DatePipe } from '@angular/common';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-list-base',
  templateUrl: './list-base.component.html',
  styleUrls: ['./list-base.component.scss']
})
export class ListBaseComponent extends BaseComponent {

  protected activatedRoute:ActivatedRoute;
  private datePipe: DatePipe;
  protected modalService:ModalService;

  perPage:number=APP_CONST.PER_PAGE;
  pageSizeOptions = APP_CONST.PAGE_SIZE_OPTIONS;
  currentPage = 0;
  totalRecords = 0;

  constructor(injector:Injector) {
    super(injector)
    this.activatedRoute=injector.get(ActivatedRoute);
    this.datePipe=injector.get(DatePipe);
    this.modalService=injector.get(ModalService);
  }

  updateQueryParam(param:any): void {
    this.currentPage = Number(param.get('page')) || 0;
    this.perPage = Number(param.get('size')) || APP_CONST.PER_PAGE;
  }


  onPageEvent(event: any, path:string) {
    this.perPage=event.pageSize;
    this.currentPage=event.pageIndex;
    const queryParam={page:this.currentPage, size:this.perPage}
    this.router.navigate([path], {queryParams:queryParam})
  }

  getLocalDate(date:string){
    return this.datePipe.transform(new Date(date), 'M/d/yy, h:mm a')
  }


}
