<mat-nav-list class="pt-0 mt-17">
  <div class="menu-heading pl-5 mb-2">
    <!-- Menu -->
  </div>
  <!-- <a mat-list-item [routerLink]="['']" (click)="selectMenu('dashboard')"
    [ngClass]="{'active': selectedMenu == 'dashboard' }">
    <mat-icon matListIcon>dashboard</mat-icon>
    <span matLine>Dashboard</span>
  </a> -->

  <ng-container *ngFor="let menu of menuList">
    <a mat-list-item [routerLink]="[menu.link]" (click)="selectMenu(menu.link)"
      [ngClass]="{'active': selectedMenu == menu.link }" *ngIf="!menu.hidden" [auth]="menu.access">
      <mat-icon mat-list-icon>{{menu.icon}}</mat-icon>
      <span matLine>{{menu.label}}</span>
      <mat-icon [ngClass]="{ 'rotate-icon': expandMenu.includes(menu.link) }" *ngIf="menu.subMenu.length"
        (click)="toggleMenu(menu.link); $event.stopPropagation(); $event.preventDefault()">expand_more</mat-icon>
    </a>
    <ng-container *ngIf="expandMenu.includes(menu.link)">
      <div class="submenu" *ngFor="let subMenu of menu.subMenu"
        [ngClass]="{'active-text': selectedMenu == menu.link && subMenu.link == menu.link+'/'+selectedSubMenu}">
        <a mat-list-item [routerLink]="[subMenu.link]" *ngIf="!subMenu.hidden" [auth]="subMenu.access">
          <mat-icon mat-list-icon>{{subMenu.icon}}</mat-icon>
          <span matLine>{{subMenu.label}}</span>
        </a>
      </div>
    </ng-container>
  </ng-container>
</mat-nav-list>
