<div class="table-container cdss-table">
    <table class="w-full " mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef> S.No </th>
            <td mat-cell *matCellDef="let row; let i = index"> {{i + 1}} </td>
        </ng-container>

        <ng-container *ngFor="let col of columns" [matColumnDef]="col.key">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{col.label}} </th>
            <td mat-cell *matCellDef="let row"> {{row[col.key]}}
            </td>
        </ng-container>

        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Country</th>
            <td mat-cell *matCellDef="let row;let i = index">
                <div class="w-40">
                    <app-select-form-control label="Country" [(ngModel)]="list[i].country"
                        (ngModelChange)="onChangeValue()" [hideLabel]="true" [options]="countriesOptions"
                        [hideError]="true">
                    </app-select-form-control>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="gender">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> Gender</th>
            <td mat-cell *matCellDef="let row;let i = index" class="w-20  p-2">
                <div class="w-36">
                    <app-select-form-control label="Gender" [(ngModel)]="list[i].gender"
                        (ngModelChange)="onChangeValue()" [hideLabel]="true" [options]="genderOptions"
                        [hideError]="true">
                    </app-select-form-control>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="fromAge">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> From Age</th>
            <td mat-cell *matCellDef="let row;let i = index" class="w-16  p-2">
                <div class="w-28">
                    <app-text-form-control label="From Age" [(ngModel)]="list[i].fromAge"
                        (ngModelChange)="onChangeValue()" [type]="inputType.Number" [showLabel]="false"
                        [hideError]="true">
                    </app-text-form-control>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="toAge">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> To Age</th>
            <td mat-cell *matCellDef="let row;let i = index" class="w-16  p-2">
                <div class="w-28">
                    <app-text-form-control label="To Age" [(ngModel)]="list[i].toAge" (ngModelChange)="onChangeValue()"
                        [type]="inputType.Number" [showLabel]="false" [hideError]="true">
                    </app-text-form-control>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="range">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Range</th>
            <td mat-cell *matCellDef="let row; let i = index" class="w-20  p-2">
                <div class="w-32">
                    <app-select-form-control label="Range" [(ngModel)]="list[i].valueRange"
                        (ngModelChange)="onChangeValue()" [hideLabel]="true" [options]="priorityPropertiesOptions"
                        [hideError]="true">
                    </app-select-form-control>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="weightage">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Weightage</th>
            <td mat-cell *matCellDef="let row;let i = index" class="w-16  p-2">
                <div class="w-28">
                    <app-text-form-control label="Weightage" [(ngModel)]="list[i].weightage"
                        (ngModelChange)="onChangeValue()" [type]="inputType.Number" [showLabel]="false"
                        [hideError]="true">
                    </app-text-form-control>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="coMorbidity">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Co Morbidity</th>
            <td mat-cell *matCellDef="let row;let i = index" class="w-16  p-2">
                <div class="w-28">
                    <app-select-form-control label="Co Morbidity" [(ngModel)]="list[i].coMorbidities"
                        (ngModelChange)="onChangeValue()" [hideLabel]="true" [hideError]="true" [searchable]="true">
                    </app-select-form-control>
                </div>
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef="action" *ngIf="buttons.length">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let row">
                <div class="flex gap-2 justify-c items-center">
                    <button *ngFor="let button of buttons" mat-icon-button [color]="button.color"
                        (click)="onClickAction(button.name, row)">
                        <mat-icon>{{button.icon}}</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="displayColumns" class="bg-secondary"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"
            [ngStyle]="{'backgroundColor': row.rowColor ? row.rowColor : 'inherit' }"></tr>
    </table>
    <div class="text-center font-medium my-3" *ngIf="!list.length">
        No Data Found
    </div>
</div>