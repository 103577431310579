<div class="flex flex-col gap-1">
    <label [for]="label" class="font-medium">{{label}} <span class="text-rose-500 font-bold"
            *ngIf="required">*</span></label>
    <mat-form-field appearance="fill" floatPlaceholder="never" class="border mb-1"
        [ngClass]="[hasError ? 'border-rose-500' : 'border-gray-400']">
        <textarea matInput [(ngModel)]="value" (ngModelChange)="onChangeValue()" [rows]="rows"
            [placeholder]="label"></textarea>
    </mat-form-field>
    <div class="text-rose-500 m-0 relative error-container mb-4">
        <ng-content></ng-content>
    </div>
</div>