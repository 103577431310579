import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Button } from '../../interfaces/button.interface';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  buttons: Button[] = [
    {
      label: 'Ok',
      value: 'ok',
      color: 'primary',
      themeType: 'raised',
    },
    {
      label: 'Close',
      value: 'close',
      color: 'warn',
      themeType: 'stroked',
    },
  ];
  message = '';
  title = 'Confirmation';

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.buttons = this.data.buttons;
    this.message = this.data.message;
    this.title = this.data?.title ? this.data.title : 'Confirmation';
  }

  onClick(value: string) {
    this.dialogRef.close(value);
  }
}
