import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AccessService } from 'src/app/core/services/access.service';

@Directive({
  selector: '[auth]',
})
export class AuthorizationDirective implements OnInit {
  @Input() auth!: number | number[];

  constructor(
    private elementRef: ElementRef,
    private accessService: AccessService
  ) {}

  ngOnInit(): void {
    const hasAccess = this.accessService.checkAccess(this.auth);
    if (!hasAccess) {
      this.elementRef.nativeElement.remove();
    }
  }
}
