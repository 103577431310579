import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { map, Observable, of, startWith } from 'rxjs';
import { BaseComponent } from 'src/app/core/components/base/base.component';
import { ApiResponse } from 'src/app/core/interfaces/api-response.interface';
import { AppHttpService } from 'src/app/core/services/app-http.service';
import { SelectOption } from 'src/app/shared/model/select-option.model';

@Component({
  selector: 'app-select-form-control',
  templateUrl: './select-form-control.component.html',
  styleUrls: ['./select-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFormControlComponent),
      multi: true,
    },
  ],
})
export class SelectFormControlComponent
  extends BaseComponent
  implements OnInit, ControlValueAccessor, OnChanges
{
  @ViewChild('search') searchTextBox!: ElementRef;

  @Input() label: string = '';
  @Input() multiple = false;

  /**
   * @deprecated - It search with server
   */
  @Input() searchable = false;
  @Input() hideLabel = false;
  @Input() hideError = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() hasError: boolean | undefined = false;
  @Input() showSuffixButton = false;
  @Input() options: SelectOption[] = [];
  /**
   * It enables search within the options passed from parent
   */
  @Input() enableSearch:boolean = false;
  @Output() onChange = new EventEmitter<string>();
  @Output() onButtonClick = new EventEmitter<string>();
  searchText = '';
  value: any;
  searchTerm: string = '';


  selectFormControl = new FormControl();
  searchTextboxControl = new FormControl();
  selectedValues: any[] = [];
  filteredOptions: SelectOption[] = [];

  propagateChange = (_: any) => {};
  touchChange = (_: any) => {};

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.searchable) {
      this.searchTextboxControl.valueChanges
        .pipe(startWith<string>(''))
        .subscribe((name) => this._filter(name));
    }else{
      this.searchTextboxControl.valueChanges
      .pipe(startWith<string>(''))
      .subscribe((name) => this.filterOptions(name));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['options']?.currentValue){
      this.filteredOptions = this.options;
    }
  }

  writeValue(obj: any): void {
    if (obj === undefined) return;
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchChange = fn;
  }

  onChangeValue(): void {
    this.propagateChange(this.value);
  }

  selectionChange(event: any) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1);
    }
  }

  openedChange(e: any) {
    // Set search textbox value as empty while opening selectbox
    this.searchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event: any) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  /**
   * Set selected values to retain the state
   */
  setSelectedValues() {
    if (
      this.selectFormControl.value &&
      this.selectFormControl.value.length > 0
    ) {
      this.selectFormControl.value.forEach((e: any) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }

  private async _filter(searchText: string): Promise<void> {
    const payload = {
      limit: 10,
      offset: 0,
      take: 10,
      skip: 0,
      where: searchText?.trim()
        ? {
            name: searchText,
          }
        : {},
    };

    try {
      const response: ApiResponse<[any[], number]> =
        await this.httpService.httpRequest<[any[], number]>(
          'disease',
          'find-all-and-count-by-admin',
          payload
        );
      if (response.status) {
        const [list] = response.data ?? [[], 0];
        this.filteredOptions = list.map((data) => ({
          label: `${data.name} (${data.code})`,
          value: data.id,
        }));
      }
    } catch (e) {
      this.appCatchError(e, 'getTestList');
    }
  }

  filterOptions(filterValue: string) {
    // const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredOptions = this.options.filter(option =>
      option.label.toLowerCase().includes(filterValue)
    );
  }
}
