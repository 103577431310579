<mat-sidenav-container class="layout-container">
    <mat-sidenav #sidenav mode="side" [(opened)]="opened" (opened)="events.push('open!')"
        (closed)="events.push('close!')">
        <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-header (toggleMenu)="toggleDrawer()"></app-header>
        <div>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<app-loader [loading]="showLoader"></app-loader>