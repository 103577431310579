<div class="flex flex-col relative">
    <label *ngIf="showLabel" [for]="label" class="mb-1 font-medium">{{label}} <span class="text-rose-500 font-bold"
            *ngIf="required">*</span></label>
    <mat-form-field appearance="fill" floatPlaceholder="never" class="border"
        [ngClass]="[hasError ? 'border-rose-500' : 'border-gray-400']">
        <mat-label>{{label}}</mat-label>
        <ng-container *ngIf="autocomplete">
            <input matInput type="{{type}}" [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="onChangeValue()"
                [placeholder]="label" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onSelectOption($event)">
                <mat-option *ngFor="let option of autocompleteOptions" [value]="option[valueKey]">
                    {{option[labelKey]}}
                </mat-option>
            </mat-autocomplete>
        </ng-container>
        <input matInput type="{{type}}" [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="onChangeValue()"
            [placeholder]="label" *ngIf="!autocomplete" [autocomplete]="autocompleteValue"
            (keyup.enter)="onClickEnter.emit(value)">
        <button type="button" *ngIf="showSuffixButton" mat-icon-button matSuffix color="primary"
            (click)="onButtonClick.emit(value)" [attr.aria-label]="'Search List'">
            <mat-icon>search</mat-icon>
        </button>
    </mat-form-field>
    <div class="text-warn m-0 relative error-container mt-2 mb-4" *ngIf="!topBarSearch && !hideError">
        <ng-content></ng-content>
    </div>
</div>