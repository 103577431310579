import { InputType } from 'src/app/shared/enums/input-type.enum';

import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AccessType } from 'src/app/core/enums/access-type.enum';

@Component({
  selector: 'app-text-form-control',
  templateUrl: './text-form-control.component.html',
  styleUrls: ['./text-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFormControlComponent),
      multi: true,
    },
  ],
})
export class TextFormControlComponent implements OnInit, ControlValueAccessor {
  @Input() label: string = '';
  @Input() type: InputType = InputType.Text;
  @Input() hasError: boolean | undefined = false;
  @Input() showSuffixButton = false;
  @Output() onChange = new EventEmitter<string>();
  @Output() onButtonClick = new EventEmitter<string>();
  @Output() selectedOption = new EventEmitter<any>();
  @Output() onClickEnter = new EventEmitter<any>();
  @Input() showLabel = true;
  @Input() hideError = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() autocomplete = false;
  @Input() clearable = false;
  @Input() autocompleteOptions: any[] = [];
  @Input() valueKey = 'value';
  @Input() labelKey = 'label';
  @Input() autocompleteValue = 'on';
  @Input() topBarSearch = false;
  @Input() accessType?: AccessType;

  value: any;
  propagateChange = (_: any) => {};
  touchChange = (_: any) => {};

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['autocompleteOptions']) {
      this.autocompleteOptions =
        changes['autocompleteOptions'].currentValue ?? [];
    }
  }

  writeValue(obj: any): void {
    if (obj === undefined) return;

    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchChange = fn;
  }

  ngOnInit(): void {}

  onChangeValue(): void {
    this.onChange.emit(this.value);
    this.propagateChange(this.value);
  }

  onSelectOption(event: MatAutocompleteSelectedEvent) {
    this.selectedOption.emit(event.option.value);
    if (this.clearable) {
      this.value = '';
      this.onChangeValue();
      console.log('clearble', this.value);
    }
  }
}
