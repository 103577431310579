<div class="page-container">
  <div class="flex items-center justify-between  px-6 header">
    <div class="title-section flex items-center gap-2 m-0">
      <mat-icon (click)="goBack()" aria-hidden="false" aria-label="Go back icon" color="primary"
        class="font-bold cursor-pointer" title="Back">
        arrow_back</mat-icon>
      <h1 class="font-medium capitalize m-0">{{title}}</h1>
      <button mat-stroked-button color="primary" *ngIf="showAdd" (click)="goToAddPage()" class="ml-4" [auth]="addAccessCode">
        <mat-icon class="align-top mr-2">add</mat-icon>Add
      </button>
    </div>
    <div class="flex items-center gap-2 m-0">
      <app-text-form-control [type]="inputType.Search" *ngIf="showSearch" [label]="showSearch" [showSuffixButton]="true"
      (onClickEnter)="getList($event)" (onChange)="onChange($event)" [autocomplete]="true"
      [autocompleteOptions]="optionList" (onButtonClick)="getList($event)" [showLabel]="false" class="w-96"
      [topBarSearch]="true" (selectedOption)="onOptionSelected($event)">
    </app-text-form-control>
    <mat-icon *ngIf="help" [title]="help">help</mat-icon>
    </div>
  </div>
  <div class="py-5 px-8">
    <ng-content></ng-content>
  </div>
</div>
