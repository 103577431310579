import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { AuthService } from '../../auth/services/auth.service';
import { AccessService } from './access.service';
import { MessageService } from './message-service.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})

/**
 * service class for routing guard
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
export class AuthGuard implements CanActivate {
  /**
   * Creates an instance of AuthGuard.
   * @param {Router} router
   * @param {AuthService} authService
   * @memberof AuthGuard
   */
  constructor(
    private router: Router,
    private authService: AuthService,
    private accessService: AccessService,
    private messageService: MessageService,
    private userService: UserService
  ) {}
  /**
   * Check is valid route
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return {*}  {(Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree)}
   * @memberof AuthGuard
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    const verifyUser = this.authService.verifyUserLogin();
    if (!verifyUser) {
      console.error('Not Valid token');

      this.authService.logout();
      this.router.navigateByUrl('/auth/login');
    }
    return await this.authorizeUser(route);
  }

  async authorizeUser(route: ActivatedRouteSnapshot): Promise<boolean> {
    await this.userService.getUserProfileDetails();
    const access = route.data['access'];

    if (!access) return true;
    const hasAccess = this.accessService.checkAccess(access);
    if (!hasAccess) {
      this.messageService.errorToaster("You don't have access.");
    }

    return hasAccess;
  }

  /**
   * Check is valid route for child
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return {*}
   * @memberof AuthGuard
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
