import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { LoggerService } from 'src/app/core/services/log.service';
import { UserService } from 'src/app/core/services/user.service';
import { AppToken } from '../models/token.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthorizedUser = false;
  constructor(
    private logService: LoggerService,
    private userService: UserService
  ) {}

  /**
   * Save access token and change isAuthorizedUser status
   *
   * @param {string} token auth token string
   * @param {string} refreshToken refresh token string
   * @return {boolean}
   * @memberof AuthService
   */
  loginUser(token: string, refreshToken: string): boolean {
    try {
      this.setAccessToken(token);
      this.setRefreshToken(refreshToken);
      this.isAuthorizedUser = true;
      return this.isAuthorizedUser;
    } catch (err) {
      this.isAuthorizedUser = false;
      this.logService.exception('Something went wrong', 101);
      return this.isAuthorizedUser;
    }
  }

  getDecodedToken(): AppToken {
    const token = this.getAccessToken();
    const decodeToken = jwtDecode(token);
    return decodeToken as AppToken;
  }

  /**
   * Get access token from local storage
   *
   * @return {*}  {(string | null)}
   * @memberof AuthService
   */
  getAccessToken(): string {
    try {
      return localStorage.getItem('token') ?? '';
    } catch (error) {
      this.logService.exception('Exception in getAccess Token', 104);
      return '';
    }
  }

  /**
   * save access token and save isLoggedIn status to local storage
   *  private function
   * @private
   * @param {string} token token string get after authentication request
   * @memberof AuthService
   */
  private setAccessToken(token: string) {
    try {
      localStorage.setItem('token', token);
      // set access Token expire time
      const millisecondForADay = 24 * 60 * 60 * 1000;
      const nextHour = new Date().getTime() + millisecondForADay;
      localStorage.setItem('tokenExpiryTime', nextHour.toString());
    } catch (error) {
      this.logService.exception('Exception in setAccess Token', 105);
    }
  }

  /**
   * set refresh tokens
   *
   * @private
   * @param {string} refreshToken
   */
  private setRefreshToken(refreshToken: string) {
    try {
      localStorage.setItem('refreshToken', refreshToken);
      // set access Token expire time
      const millisecondForADay = 24 * 60 * 60 * 1000;
      const nextDay = new Date().getTime() + millisecondForADay;
      localStorage.setItem('refreshTokenExpiryTime', nextDay.toString());
    } catch (error) {
      this.logService.exception('Exception in setAccess Token', 105);
    }
  }

  /**
   * Verify user login
   *
   * @return {*}  {boolean}
   * @memberof AuthService
   */
  verifyUserLogin(): boolean {
    const token = localStorage.getItem('token');
    const tokenExpiryTime = localStorage.getItem('tokenExpiryTime');
    const isTimeNotExpired = +tokenExpiryTime! > new Date().getTime();
    const isValidUser = token && isTimeNotExpired;

    if (isValidUser) {
      this.isAuthorizedUser = true;
      return this.isAuthorizedUser;
    } else {
      this.isAuthorizedUser = false;
      return this.isAuthorizedUser;
    }
  }

  /**
   * Logout user and reset all auth activity
   *
   * @memberof AuthService
   */
  logout() {
    try {
      // TODO:(jeni): isApi call needed?
      this.isAuthorizedUser = false;
      this.userService.clearUserDetails();
      localStorage.clear();
    } catch (error) {
      this.logService.exception('Exception in logout', 107);
    }
  }
}
