/* eslint-disable linebreak-style */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/**
 * Factory function for translate module
 *
 * @export
 * @param {HttpClient} http
 * @return {*}
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@Injectable({
  providedIn: 'root',
})

/**
 * Service for handle application internationalization
 *
 * @export
 * @class AppTranslateService
 */
export class AppTranslateService {
  /**
   * Creates an instance of AppTranslateService.
   * @memberof AppTranslateService
   */
  constructor(private translateService: TranslateService) {
    // set the default language.
    // this.setTranslateLanguage('ta');
  }
  /**
   * To change translation language
   *
   * @param {string} language
   * @memberof AppTranslateService
   */
  setTranslateLanguage(language: string): void {
    this.translateService.use(language).subscribe((result: any) => {
      // console.log(result);
    });
  }
}
