<div class="flex flex-col gap-1">
    <label for="dob">{{label}} <span class="text-rose-500 font-bold" *ngIf="required">*</span></label>
    <mat-form-field floatLabel="never" appearance="fill" class="border mb-1"
        [ngClass]="[hasError ? 'border-rose-500' : 'border-gray-400']">
        <mat-label>Select {{label}}</mat-label>
        <input matInput [(ngModel)]="value" (ngModelChange)="onChangeValue()" [matDatepicker]="picker" [max]="maxValue"
            readonly (click)="picker?.open()" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div class="text-rose-500 m-0 relative error-container mb-4">
        <ng-content></ng-content>
    </div>
</div>