<mat-toolbar color="primary" class="sticky top-0">
    <button mat-icon-button class="toggle-icon" (click)="toggleMenu.emit()"
        aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
    </button>
    <!-- <span>My App</span> -->
    <span class="flex-1"></span>
    <!-- <button mat-icon-button matBadge="5" matBadgePosition="after" matBadgeColor="warn" [matBadgeHidden]="badgeHidden"
        (click)="toggleBadgeVisibility()">
        <mat-icon>message</mat-icon>
    </button> -->
    <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item>
            <mat-icon>account_circle</mat-icon>
            <span>Profile</span>
        </button>
    <button mat-menu-item [routerLink]="[ '/user/change-password']">
        <mat-icon>lock</mat-icon>
        <span>Change Password</span>
    </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>login</mat-icon>
        <span>Logout</span>
    </button>
    </mat-menu> -->
</mat-toolbar>