export enum ActionType {
  ADD = 1,
  EDIT = 2,
  DELETE = 3,
  SINGLE_VIEW = 4,
  LIST_VIEW = 5,
  FIND_ALL_LIST_VIEW = 6,
  FIND_ONE_VIEW = 7,
  IS_EXISTS = 8,
  GET_ONE_BY_COLUMN = 9,
  ADMIN_SINGLE_VIEW = 11,
  ADMIN_LIST_VIEW = 12,
  ADMIN_FIND_ALL_LIST_VIEW = 13,
  ADMIN_FIND_ONE_VIEW = 14,
  ADMIN_EDIT = 15,
  ADMIN_DELETE = 16,
  ADMIN_IS_EXISTS = 17,
  GET_ONE_BY_COLUMN_BY_ADMIN = 18,
  FIND_ALL_DELETED = 19,
  RESTORE_DELETED_ONE = 20,
  DELETE_RECORD_PERMANENTLY = 21,
  PRIORITY_DELETE_RECORD_PERMANENTLY = 2223,
  GET_ALL_ORGANIZATION = 724
}
