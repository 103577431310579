import { Component } from '@angular/core';
import * as LogRocket from 'logrocket';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bdms-ui';

  initLogRocket() {
    if (environment.production) {
      LogRocket.init('ehszn6/cdss-dev');
    }
  }
}
