import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DEFAULT_BUTTON } from 'src/app/core/constants/app.constant';
import { Button } from 'src/app/core/interfaces/button.interface';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() title = 'Title';
  @Input() buttons: Button[] = [DEFAULT_BUTTON];
  @Output() btnClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onBtnClick(value: string): void {
    this.btnClick.emit(value);
  }
}
