import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';

import { LoaderService } from '../core/services/loader.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  opened: boolean = true;
  events: string[] = [];
  shouldRun = false;
  showLoader = false;
  constructor(private loaderService: LoaderService, private ngZone: NgZone) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.loaderService.loader.subscribe((value) => {
      this.ngZone.run(() => {
        setTimeout(() => {
          this.showLoader = value;
        }, 0)
      });
    });
  }

  toggleDrawer() {
    this.opened = !this.opened;
  }
}
