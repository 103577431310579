<div class="modal-container flex flex-col">
    <h2 class="font-medium m-0" mat-dialog-title>{{title}}</h2>
    <mat-divider>
    </mat-divider>
    <div mat-dialog-content class="flex-1">
        <div class="mt-4">
            <p>{{message}}</p>
        </div>
    </div>
    <div mat-dialog-actions class="flex justify-end gap-2">
        <ng-container *ngFor="let button of buttons">
            <ng-container [ngSwitch]="button.themeType">
                <button *ngSwitchCase="'raised'" type="button" mat-stroked-button [color]="button.color"
                    (click)="onClick(button.value)">{{button.label}}</button>
                <button *ngSwitchCase="'stroked'" type="button" mat-stroked-button [color]="button.color"
                    (click)="onClick(button.value)">{{button.label}}</button>
                <button *ngSwitchCase="'flat'" type="button" mat-flat-button [color]="button.color"
                    (click)="onClick(button.value)">{{button.label}}</button>
                <button *ngSwitchCase="'icon'" type="button" mat-icon-button [color]="button.color"
                    (click)="onClick(button.value)">
                    <mat-icon>{{button.label}}</mat-icon>
                </button>

                <button *ngSwitchDefault type="button" mat-button [color]="button.color"
                    (click)="onClick(button.value)">{{button.label}}</button>
            </ng-container>
        </ng-container>
    </div>
</div>
