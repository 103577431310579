<div class="test-condition-container">
    <h3 class="font-bold">{{title}}</h3>
    <div class="w-full max-w-xs" *ngIf="!hideSearch">
        <app-text-form-control [clearable]="true" label="Code/Name" [showSuffixButton]="true" [autocomplete]="true"
            [autocompleteOptions]="optionList" (selectedOption)="onOptionSelected($event)"
            (onChange)="findTest($event)">
        </app-text-form-control>
    </div>
    <app-table [columns]="displayColumns" [hidePagination]="true" [dataList]="list" [buttons]="buttons"
        (action)="onAction($event)">
    </app-table>
</div>