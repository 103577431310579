import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { debounceTime } from 'rxjs';
import { SelectOption } from 'src/app/shared/model/select-option.model';

@Component({
  selector: 'app-chip-form-control',
  templateUrl: './chip-form-control.component.html',
  styleUrls: ['./chip-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipFormControlComponent),
      multi: true,
    },
  ],
})
export class ChipFormControlComponent implements OnInit {
  @Input() label = '';
  @Input() hideLabel = false;
  @Input() required = false;
  @Input() dataList: SelectOption[] = [];
  @Output() onChange = new EventEmitter<SelectOption[]>();
  @Output() searchKey = new EventEmitter<string>();

  propagateChange = (_: any) => {};
  touchChange = (_: any) => {};

  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredList: SelectOption[] = [];
  selectedList: SelectOption[] = [];

  value: any[] = [];
  inputCtrl = new FormControl('');
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement> | undefined;

  constructor() {
    this.inputCtrl.valueChanges
    //.pipe(debounceTime(300))
    .subscribe((inputValue) => {
    this.filter(inputValue ?? ' ');
    this.searchKey.emit(inputValue!);
  });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataList']?.currentValue) {
      this.filteredList = this.dataList = changes['dataList'].currentValue;
    }
  }

  writeValue(obj: any): void {
    if (obj === undefined){
      this.selectedList = [];
      return;
    };
    this.value = obj;
    setTimeout(() => {
      this.selectedList = this.dataList?.filter((obj) => this.value?.includes(obj.value));
    }, 100);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchChange = fn;
  }

  onChangeValue(): void {
    this.value = this.selectedList.map((data) => data.value);
    this.propagateChange(this.value);
    this.onChange.emit(this.value);
  }

  add(event: MatChipInputEvent): void {
    const value: any = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.selectedList.push(value);
    }
    this.onChangeValue();

    // Clear the input value
    this.fruitInput!.nativeElement.value = '';
    this.inputCtrl.setValue(null);
  }

  remove(dataIndex: number): void {
    if (dataIndex >= 0) {
      this.selectedList.splice(dataIndex, 1);
      this.onChangeValue();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    const isValueExist = this.selectedList.some(id=>id==value);
    if(isValueExist) return;
    this.selectedList.push(event.option.value);
    this.fruitInput!.nativeElement.value = '';
    this.inputCtrl.setValue(null);
    this.onChangeValue();
  }

  filter(inputValue: string | SelectOption): void {
    const filterValue = typeof inputValue === 'string' ? inputValue?.toLowerCase() : inputValue.label?.toLowerCase();

    this.filteredList = this.dataList.filter(
      (data) =>
        data.label.toLowerCase().includes(filterValue) &&
        !this.value?.includes(data.value)
    );
  }
}
