export enum ToasterType {
    // eslint-disable-next-line no-unused-vars
    SUCCESS = 'success',
    // eslint-disable-next-line no-unused-vars
    ERROR = 'error',
    // eslint-disable-next-line no-unused-vars
    INFO = 'info',
    // eslint-disable-next-line no-unused-vars
    WARNING = 'warning'
}
