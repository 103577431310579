import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { APP_CONST } from 'src/app/core/constants/app.constant';
import { TableButton, TableColumn } from 'src/app/shared/model/table.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() columns: TableColumn[] = [];
  @Input() buttons: TableButton[] = [];
  @Input() dataList: any[] = [];
  @Input() totalRecords = 0;
  @Input() hidePagination: boolean = false;
  @Input() showCheck: boolean = false;
  @Input() hasOtherActions: boolean = false;
  @Input() perPage = APP_CONST.PER_PAGE;
  @Input() currentPage = 0;

  displayColumns: string[] = ['sno'];
  pageSizeOptions = APP_CONST.PAGE_SIZE_OPTIONS;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Output() changePage: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() onChecked: EventEmitter<any[]> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    // if (this.buttons.length || this.showCheck)
    //   this.displayColumns.push('action');
    // const columnKeys = this.columns.map((col) => col.key);
    // this.displayColumns.splice(1, 0, ...columnKeys);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataList']?.currentValue) {
      this.updateTable(changes['dataList'].currentValue);
    }

    if (changes['columns']?.currentValue) {
      const columnKeys = changes['columns'].currentValue.map(
        (col: TableColumn) => col.key
      );
      const cols = ['sno'];
      if (this.buttons.length || this.showCheck) cols.push('action');
      cols.splice(1, 0, ...columnKeys);
      this.displayColumns = cols;
    }
  }

  ngAfterViewInit() {
    this.resetSort();
  }

  updateTable(list: any[]) {
    this.dataSource.data = list;
    this.resetSort();
  }

  resetSort() {
    this.dataSource.sort = this.sort;
  }

  onPageChange(event: any): void {
     this.perPage = event.pageSize;
     this.currentPage = event.pageIndex;
    this.changePage.emit(event);
  }

  onClickAction(type: any, data: number, index: number) {
    this.action.emit({
      type,
      data,
      index,
    });
  }

  onChangeCheck() {
    const list = this.dataList.filter((data) => data.checked);
    this.onChecked.emit(list);
  }
}
