import { UiAccessList } from "src/app/role/models/role.model";

export const ADD = 1;
export const EDIT = 2;
export const DELETE = 3;
export const SINGLE_VIEW = 4;
export const LIST_VIEW = 5;
export const FIND_ALL_LIST_VIEW = 6;
export const FIND_ONE_VIEW = 7;
export const IS_EXISTS = 8;
export const GET_ONE_BY_COLUMN = 9;

export const ADMIN_SINGLE_VIEW = 11;
export const ADMIN_LIST_VIEW = 12;
export const ADMIN_FIND_ALL_LIST_VIEW = 13;
export const ADMIN_FIND_ONE_VIEW = 14;
export const ADMIN_EDIT = 15;
export const ADMIN_DELETE = 16;
export const ADMIN_IS_EXISTS = 17;
export const GET_ONE_BY_COLUMN_BY_ADMIN = 18;
export const FIND_ALL_DELETED = 19;
export const RESTORE_DELETED_ONE = 20;

/**
 * Access list to use in @RoleListComponent
 * List access should enable if user has any access
 */

 const LIST_ACCESS = ['list', 'find-all-and-count', ];
 const ADMIN_LIST_ACCESS = ['get-all-by-admin', 'find-all-and-count-by-admin', ];



export const UI_ACCESS_LIST: UiAccessList = {
  LIST_ACCESS: ['list', 'find-all-and-count'],
  ADD_ACCESS: ['add', 'is-exist', 'is-exist-by-admin', ...LIST_ACCESS],
  EDIT_ACCESS: ['edit', 'update', 'is-exist', 'is-exist-by-admin', 'get-one-by-column', 'find-one', ...LIST_ACCESS],
  VIEW_ACCESS: ['view', 'get-one-by-column', 'find-one', ...LIST_ACCESS],
  DELETE_ACCESS: ['delete', ...LIST_ACCESS],

  ADMIN_LIST_ACCESS: ['get-all-by-admin', 'find-all-and-count-by-admin'],
  ADMIN_ADD_ACCESS: ['add', 'is-exist-by-admin', ...ADMIN_LIST_ACCESS],
  ADMIN_EDIT_ACCESS: ['update-by-admin', 'is-exist-by-admin', 'get-one-by-column-by-admin', 'find-one-by-admin', ...ADMIN_LIST_ACCESS],
  ADMIN_VIEW_ACCESS: ['get-by-admin', 'get-one-by-column-by-admin', 'find-one-by-admin', ...ADMIN_LIST_ACCESS],
  ADMIN_DELETE_ACCESS: ['delete-by-admin', ...ADMIN_LIST_ACCESS],
  DELETE_RESTORE_ACCESS: ['find-all-deleted', 'restore-deleted-one', ...ADMIN_LIST_ACCESS],
  DELETED_LIST_ACCESS: ['find-all-deleted', ...ADMIN_LIST_ACCESS],
  OTHERS: []
};

export const ACCESS_LIST = ['LIST_ACCESS', 'ADD_ACCESS', 'EDIT_ACCESS', 'VIEW_ACCESS', 'DELETE_ACCESS', 'ADMIN_LIST_ACCESS', 'ADMIN_ADD_ACCESS', 'ADMIN_EDIT_ACCESS', 'ADMIN_VIEW_ACCESS', 'ADMIN_DELETE_ACCESS', 'DELETE_RESTORE_ACCESS', 'DELETED_LIST_ACCESS'];



