<div class="table-container cdss-table">
    <h3 class="font-bold">{{title}}</h3>
    <div class="w-full max-w-xs" *ngIf="!hideSearch">
        <app-text-form-control [clearable]="true" label="{{title}} Name/Short Name" [showSuffixButton]="true"
            [autocomplete]="true" [autocompleteOptions]="optionList" (selectedOption)="onOptionSelected($event)"
            (onChange)="findData($event)">
        </app-text-form-control>
    </div>
    <table class="w-full " mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef> S.No </th>
            <td mat-cell *matCellDef="let row; let i = index"> {{i + 1}} </td>
        </ng-container>

        <ng-container *ngFor="let col of columns" [matColumnDef]="col.key">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{col.label}} </th>
            <td mat-cell *matCellDef="let row"> {{row[col.key]}}
            </td>
        </ng-container>
        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Country</th>
            <td mat-cell *matCellDef="let row;let i = index">
                <div class="w-32">
                    <app-select-form-control label="Country" [(ngModel)]="list[i].country"
                        (ngModelChange)="onChangeValue()" [hideLabel]="true" [options]="countriesOptions"
                        [hideError]="true">
                    </app-select-form-control>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="gender">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> Gender</th>
            <td mat-cell *matCellDef="let row;let i = index" class="w-20  p-2">
                <div class="w-24">
                    <app-select-form-control label="Gender" [(ngModel)]="list[i].gender"
                        (ngModelChange)="onChangeValue()" [hideLabel]="true" [options]="genderOptions"
                        [hideError]="true">
                    </app-select-form-control>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="ageGroup">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> Age Group</th>
            <td mat-cell *matCellDef="let row;let i = index" class="w-20  p-2">
                <div class="w-24">
                    <app-select-form-control label="Age Group" [(ngModel)]="list[i].ageGroup"
                        (ngModelChange)="onChangeValue()" [hideLabel]="true" [options]="ageGroupOptions"
                        [hideError]="true">
                    </app-select-form-control>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="range">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Range</th>
            <td mat-cell *matCellDef="let row; let i = index" class="w-20  p-2">
                <div class="w-32">
                    <app-select-form-control label="Range" [(ngModel)]="list[i].valueRange"
                        (ngModelChange)="onChangeValue()" [hideLabel]="true" [options]="priorityPropertiesOptions"
                        [hideError]="true">
                    </app-select-form-control>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="weightage">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Weightage</th>
            <td mat-cell *matCellDef="let row;let i = index" class="w-16  p-2">
                <div class="w-20">
                    <app-text-form-control label="Weightage" [(ngModel)]="list[i].weightage"
                        (ngModelChange)="onChangeValue()" [type]="inputType.Number" [showLabel]="false"
                        [hideError]="true">
                    </app-text-form-control>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="coMorbidity">
            <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Co Morbidity</th>
            <td mat-cell *matCellDef="let row;let i = index" class="w-16  p-2">
                <div class="w-40">
                    <app-select-form-control label="Co Morbidity" [(ngModel)]="list[i].coMorbidities"
                        (ngModelChange)="onChangeValue()" [hideLabel]="true" [hideError]="true" [searchable]="true"
                        [multiple]="true">
                    </app-select-form-control>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="action" *ngIf="buttons.length">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let row;let i = index">
                <div class="flex gap-2 justify-c items-center">
                    <button *ngFor="let button of buttons" mat-icon-button [color]="button.color"
                        (click)="onClickAction(button.name, row, i)">
                        <mat-icon>{{button.icon}}</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayColumns" class="bg-secondary"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"
            [ngStyle]="{'backgroundColor': row.rowColor ? row.rowColor : 'inherit' }"></tr>
    </table>
    <div class="text-center font-medium my-3" *ngIf="!list.length">
        No Data Found
    </div>
</div>