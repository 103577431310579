import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RoleAccess } from '../enums/role-access.enum';
import { Access } from '../interfaces/access.interface';
import { ApiResponse } from '../interfaces/api-response.interface';
import { Profile, User } from '../models/user.model';
import { AccessService } from './access.service';
import { AppHttpService } from './app-http.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user!: User | undefined;
  profilePicture: string = '';

  constructor(
    private http: AppHttpService,
    private accessService: AccessService
  ) {}

  /**
   * get user details
   *
   * @return {*}  {(User | undefined)}
   * @memberof UserService
   */
  getUserDetails(): User | undefined {
    return this.user;
  }

  /**
   * Get user profile details
   *
   * @return {*}
   * @memberof UserService
   */
  async getUserProfileDetails(): Promise<any> {
    if (this.user) {
      return { user: this.user, access: {} };
    }
    const response: ApiResponse<User> = await this.http.get(
      'user/user-profile-details-using-token',
      {
        endPoint: environment.authEndPointUrl,
      }
    );
    if (response.status) {
      const profile: Profile = response.data as unknown as Profile;
      this.setUserDetails(profile.user);
      this.http.setAccessList(profile.access);
      //console.log(JSON.stringify(profile.access.accessList));

      this.accessService.setAccess(profile.access.accessList ?? []);
      this.accessService.setUserAccess(profile.access.userRoles ?? []);
      this.profilePicture = profile?.user?.profilePictureUrl
        ? environment.endPointUrl + profile?.user?.profilePictureUrl
        : this.profilePicture;
      return profile;
    } else {
      return null;
    }
  }

  /**
   * set User details
   *
   * @param {User} user
   * @memberof UserService
   */
  setUserDetails(user: User) {
    this.user = user;
  }

  /**
   * Clear user details
   *
   * @memberof UserService
   */
  clearUserDetails() {
    this.user = undefined;
  }
}
