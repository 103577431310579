import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BaseComponent } from './components/base/base.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MessageComponent } from './components/message/message.component';
import { SharedModule } from '../shared/shared.module';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ListBaseComponent } from './components/list-base/list-base.component';

@NgModule({
  declarations: [
    BaseComponent,
    PageContainerComponent,
    LoaderComponent,
    MessageComponent,
    ConfirmComponent,
    ListBaseComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    PageContainerComponent,
    BaseComponent,
    LoaderComponent,
    MessageComponent,
  ],
  providers: [DatePipe],
})
export class CoreModule {}
