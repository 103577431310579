import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MENU_LIST } from 'src/app/core/constants/app.constant';
import { AccessService } from 'src/app/core/services/access.service';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
})
export class MenuListComponent implements OnInit {
  expand = false;
  expandSub: boolean = false;
  expandMenu: string[] = [];
  expandSubMenu: string = '';
  selectedMenu = '';
  selectedSubMenu = '';
  menuList: any[] = [];

  constructor(private router: Router, private accessService: AccessService) {}

  ngOnInit(): void {
    this.initMenu();
    this.updateSelectedMenu();
    this.router.events.subscribe(() => {
      this.updateSelectedMenu();
    });
  }

  updateSelectedMenu() {
    const [, menu, subMenu] = this.router.url.split('/');
    this.selectedMenu = menu.split('?')[0] ?? '';
    this.selectedSubMenu = subMenu ?? '';

    if (subMenu) {
      // this.expand = true;
      this.updateExpandMenu(menu);
    }
  }

  toggleMenu(name: string): void {
    if (this.expandMenu.includes(name)) {
      // this.expand = !this.expand;
      const index = this.expandMenu.indexOf(name);
      this.expandMenu.splice(index, 1);
    } else {
      this.expand = false;
      this.updateExpandMenu(name);
      // this.expand = true;
      this.expandSub = false;
      this.expandSubMenu = '';
    }
  }

  toggleSubMenu(name: string): void {
    if (name === this.expandSubMenu) {
      this.expandSub = !this.expandSub;
    } else {
      this.expandSub = false;
      this.expandSubMenu = name;
      this.expandSub = true;
    }
  }

  selectMenu(menu: string) {
    this.selectedMenu = menu;
    if (this.expandMenu.includes(menu)) {
      // this.expand = false;
      // this.expandMenu = '';
    }
  }

  initMenu(): void {
    this.menuList = MENU_LIST.map((menu) => {
      menu.subMenu = menu.subMenu.map((subMenu) => {
        subMenu.hidden = subMenu.hidden
          ? !this.accessService.checkAccess(subMenu.access)
          : false;
        return subMenu;
      });
      menu.hidden = menu.hidden
        ? !this.accessService.checkAccess(menu.access)
        : false;
      return menu;
    });
   // this.accessService.checkAccess;
  }

  updateExpandMenu(menu: string) {
    this.expandMenu = [...new Set([...this.expandMenu, menu])];
  }
}
