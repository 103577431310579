<div class="flex flex-col gap-1" *ngIf="!searchable">
  <label [for]="label" *ngIf="!hideLabel" class="font-medium">{{label}} <span class="text-rose-500 font-bold"
      *ngIf="required">*</span></label>
  <mat-form-field appearance="fill" floatPlaceholder="never" class="border mb-1"
    [ngClass]="[hasError ? 'border-rose-500' : 'border-gray-400']" floatLabel="never">
    <mat-label>Select {{label}} *</mat-label>
    <mat-select id="gender" [multiple]="multiple" [placeholder]="label" [(ngModel)]="value"
      (ngModelChange)="onChangeValue()" [disabled]="disabled">
      <div class="py-2">
        <mat-optgroup *ngIf="enableSearch">
          <mat-form-field style="width:100%;">
            <input #search autocomplete="off" placeholder="Search" aria-label="Search" matInput
              [formControl]="searchTextboxControl">
            <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="clearSearch($event)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </mat-optgroup>
      </div>
      <mat-optgroup *ngIf="filteredOptions.length == 0">
        <div>No results found!</div>
      </mat-optgroup>
      <mat-option *ngFor="let option of filteredOptions" [value]="option.value">{{option.label |
        titlecase}}</mat-option>

      <!-- <mat-option *ngFor="let option of options" [value]="option.value">{{option.label | titlecase}}</mat-option> -->
    </mat-select>
  </mat-form-field>
  <div class="text-rose-500 m-0 relative error-container mb-4" *ngIf="!hideError">
    <ng-content></ng-content>
  </div>
</div>
<div class="flex flex-col gap-1" *ngIf="searchable">
  <label [for]="label" *ngIf="!hideLabel" class="font-medium">{{label}}</label>
  <mat-form-field class="searchable">
    <mat-select (openedChange)="openedChange($event)" placeholder="Select {{label}}" [formControl]="selectFormControl"
      multiple [(ngModel)]="value" (ngModelChange)="onChangeValue()" [disabled]="disabled">
      <mat-select-trigger>
        {{selectFormControl.value ? selectFormControl.value[0] : ''}}
        <span *ngIf="selectFormControl.value?.length > 1" class="additional-selection">
          (+{{selectFormControl.value.length - 1}} {{selectFormControl.value?.length === 2 ? 'other' :
          'others'}})
        </span>
      </mat-select-trigger>
      <div class="select-container">
        <mat-optgroup>
          <mat-form-field style="width:100%;">
            <input #search autocomplete="off" placeholder="Search" aria-label="Search" matInput
              [formControl]="searchTextboxControl">
            <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="clearSearch($event)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </mat-optgroup>
        <mat-optgroup *ngIf="filteredOptions.length == 0">
          <div>No results found!</div>
        </mat-optgroup>
        <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let option of filteredOptions"
          [value]="option.value">
          {{option.label | titlecase}}
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
</div>
