import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Http } from '../enums/http.enum';
import { Access, UserAccess } from '../interfaces/access.interface';
import { ApiResponse } from '../interfaces/api-response.interface';
import { LoaderService } from './loader.service';
import { MessageService } from './message-service.service';

@Injectable({ providedIn: 'root' })
/**
 * Service for API requests
 *
 * @export
 * @class AppHttpService
 */
export class AppHttpService {
  private userAccess: number[] = [];
  private accessList?: Access[] = [];
  /**
   * Creates an instance of AppHttpService.
   * @memberof AppHttpService
   */
  constructor(
    private http: HttpClient,
    private notificationService: MessageService,
    private loaderService: LoaderService,
    private injector: Injector
  ) {}

  /**
   * For HTTP GET request
   *
   * @param {string} path
   * @return {*}  {Observable<any>}
   * @memberof AppHttpService
   */
  get(path: string, option?: any): Promise<any> {
    const endPointUrl = option?.endPoint ?? environment.endPointUrl;
    return firstValueFrom(this.http.get(endPointUrl + path));
  }

  /**
   * For HTTP POST request
   *
   * @param {string} path
   * @param {*} body
   * @param {*} option
   * @return {*}  {Observable<any>}
   * @memberof AppHttpService
   */
  post(path: string, body: any, option?: any): Observable<any> {
    const endPointUrl = option?.endPoint ?? environment.endPointUrl;
    return this.http.post(endPointUrl + path, body);
  }

  /**
   * For HTTP PUT request
   *
   * @param {string} path
   * @param {*} body
   * @param {*} option
   * @return {*}  {Observable<any>}
   * @memberof AppHttpService
   */
  put(path: string, body: any, option?: any): Observable<any> {
    const endPointUrl = option?.endPoint ?? environment.endPointUrl;
    return this.http.put(endPointUrl + path, body);
  }

  /**
   *
   *
   * @param {string} accessStr
   * @param {string} actionStr
   * @param {*} [params]
   * @param {boolean} [isMultipartFormData]
   * @param {String} [optionalParam]
   * @return {*}  {(Observable<any> | Promise<any> | any)}
   * @memberof AppHttpService
   */
  httpRequest<T>(
    accessStr: string,
    actionStr: string,
    params?: any,
    options?: any
  ): Promise<ApiResponse<T>> {
    const isMultipartFormData = options?.isMultipartFormData ?? false;
    const optionalParam = options?.optionalParam ?? '';
    const showLoader = options?.showLoader ?? true;
    const hideErrorResToaster = options?.hideErrorResToaster ?? false;
    const hideErrorLogs = options?.hideErrorLogs ?? false;
    const endPointUrl = options?.endPoint ?? environment.endPointUrl;
    if (showLoader) {
      this.loaderService.showLoader();
    }

    const access = this.checkUserAccess(accessStr, actionStr);
    if (!access) {
      showLoader && this.loaderService.hideLoader();
      if (!hideErrorLogs) {
        console.error(
          `You don't have access. Please contact Admin, module: ${accessStr}, function: ${actionStr}`
        );
      }
      return Promise.resolve({
        status: false,
        message: `You don't have access. Please contact Admin `,
      });
    }

    let url = endPointUrl + access.accessUrl;
    url += this.formatUrlParams(access.url, params).concat(optionalParam);

    let headers = new HttpHeaders({ 'access-name': access.accessPermission });

    if (isMultipartFormData) {
      headers.append('Content-Type', 'multipart/form-data');
      params = this.objectToFormData(params);
    }

    const accessHeader = {
      headers,
    };

    const response = this.makeApiCall<T>(url, params, accessHeader, access);

    return response
      .then((apiResponse) => {
        if (!apiResponse?.status) {
          if (!hideErrorResToaster) {
            this.notificationService.errorToaster(apiResponse?.message!);
          }
          if (apiResponse?.error) {
            console.log('ApiResponse Error:', apiResponse);
          }
        }
        showLoader && this.loaderService.hideLoader();
        return apiResponse;
      })
      .catch((reason) => {
        showLoader && this.loaderService.hideLoader();
        return reason;
      });
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   *
   *
   * @template T
   * @param {string} url
   * @param {*} params
   * @param {{
   *          headers: HttpHeaders;
   *         }} accessHeader
   * @param {({
   *         accessName: string;
   *         accessUrl: string;
   *         accessId: number;
   *         accessPermission: string;
   *         id: number; name: string;
   *         url: string;
   *         method?: string | undefined;
   *        accesses?: Access[] | undefined;
   *       })} access
   * @return {*}
   * @memberof AppHttpService
   */
  makeApiCall<T>(
    url: string,
    params: any,
    accessHeader: {
      headers: HttpHeaders;
    },
    access: {
      accessName: string;
      accessUrl: string;
      accessId: number;
      accessPermission: string;
      id: number;
      name: string;
      url: string;
      method?: string | undefined;
      accesses?: Access[] | undefined;
    }
  ) {
    if (access.method === Http.POST) {
      return firstValueFrom(
        this.http.post<ApiResponse<T>>(url, params, accessHeader)
      );
    } else if (access.method === Http.PUT) {
      return firstValueFrom(
        this.http.put<ApiResponse<T>>(url, params, accessHeader)
      );
    } else if (access.method === Http.DELETE) {
      return firstValueFrom(
        this.http.delete<ApiResponse<T>>(url, accessHeader)
      );
    } else if (access.method === Http.GET) {
      return firstValueFrom(this.http.get<ApiResponse<T>>(url, accessHeader));
    } else {
      return Promise.resolve({
        status: false,
        message:
          'Invalid Api Header type. Header type should be GET/POST/PUT/DELETE',
      } as ApiResponse<T>);
    }
  }

  /**
   *
   *
   * @private
   * @param {*} url
   * @param {*} [params={
   *     limit: 10,
   *     offset: 1
   *   }]
   * @return {*}  {string}
   * @memberof AppHttpService
   */
  private formatUrlParams(
    url: any,
    params: any = {
      limit: 10,
      offset: 1,
    }
  ): string {
    let urlString = '';
    url = url.split('/').splice(1);
    url.forEach((data: any) => {
      const checkUrlHasColon = data.search(':') === 0;
      if (checkUrlHasColon) {
        urlString +=
          params[data.substring(1)] !== undefined
            ? '/' + params[data.substring(1)]
            : '';
      } else {
        urlString += '/' + data;
      }
    });
    return urlString;
  }

  /**
   * format form data
   *
   * @param {*} params
   * @return {*}  {FormData}
   * @memberof AppHttpService
   */
  objectToFormData(params: any | FormData): FormData | any {
    const checkIsFormData = params instanceof FormData;
    if (checkIsFormData) {
      return params as FormData;
    }
    const checkHasFormData = Object.keys(params).includes('formData');
    if (checkHasFormData) {
      return params.formData;
    }

    return params;
  }

  /**
   * check user access
   *
   * @param {*} accessStr
   * @param {*} actionStr
   * @return {*}  {*}
   * @memberof AppHttpService
   */
  checkUserAccess(accessStr: string, actionStr: string) {
    const accessGroup = this.accessList?.filter(
      (data: Access) => data.name === accessStr
    );
    if (accessGroup?.length) {
      const access = accessGroup[0]?.accesses?.filter(
        (data: Access) => data.name === actionStr
      );
      if (access?.length) {
        // TODO: after access uncomment this
         const hasAccess = this.userAccess?.includes(access[0]?.id);
       // const hasAccess = true;
        if (hasAccess) {
          return {
            ...access[0],
            accessName: accessGroup[0].name,
            accessUrl: accessGroup[0].url,
            accessId: accessGroup[0].id,
            accessPermission: accessGroup[0].name + '~' + access[0].name,
          };
        } else {
          console.error(
            'Dhaneesh Error: You don not have access+' +
              access[0]?.id +
              ':' +
              accessStr +
              '/' +
              actionStr
          );
          console.log(302);
          return false;
        }
      } else {
        console.log(305);
        return false;
      }
    }
    console.log(308, accessStr, actionStr);

    return false;
  }

  /**
   * Set access list
   *
   * @param {UserAccess[]} access
   * @memberof UserService
   */
  setAccessList(access: UserAccess) {
    this.accessList = access.accessList;
  }

  /**
   * user access list
   *
   * @param {*} userAccess
   * @memberof AppHttpService
   */
  setUserAccess(userAccess: number[]) {
    this.userAccess = userAccess;
  }
}
