import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GENDER_OPTIONS } from 'src/app/core/constants/app.constant';
import { AppService } from 'src/app/core/services/app.service';
import { RangeValuesEnum } from 'src/app/priority/enum/priority-properties.enum';
import { InputType } from '../../enums/input-type.enum';
import { SelectOption } from '../../model/select-option.model';
import { TableButton, TableColumn } from '../../model/table.model';

@Component({
  selector: 'app-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.scss'],
})
export class EditableComponent implements OnInit {
  @Input() columns: TableColumn[] = [
    {
      label: 'Code',
      key: 'code',
    },
    {
      label: 'Name',
      key: 'name',
    },
  ];
  @Input() buttons: TableButton[] = [];
  @Input() list: any[] = [];
  @Input() totalRecords = 0;
  @Input() hidePagination: boolean = true;

  displayColumns: string[] = ['sno'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Output() changePage: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateList: EventEmitter<any[]> = new EventEmitter();

  genderOptions = GENDER_OPTIONS;
  countriesOptions: SelectOption[] = [];
  priorityPropertiesOptions: SelectOption[] = Object.values(
    RangeValuesEnum
  ).map((data) => ({
    label: data.replace(/_/g, ' '),
    value: data,
  }));
  inputType = InputType;

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.getCountryList();
    const columnKeys = this.columns.map((col) => col.key);
    this.displayColumns.splice(1, 0, ...columnKeys);
    this.displayColumns.push(
      ...[
        'country',
        'gender',
        'fromAge',
        'toAge',
        'range',
        'weightage',
        'coMorbidity',
      ]
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['list']) {
      this.updateTable(changes['list'].currentValue);
    }
  }

  async getCountryList(): Promise<void> {
    const countriesList = await this.appService.getCountryList();
    this.countriesOptions = countriesList.map((data) => ({
      label: data[1],
      value: data[1],
    }));
  }

  updateTable(list: any[]) {
    this.dataSource.data = list;
  }

  onClickAction(type: any, data: number) {
    this.action.emit({
      type,
      data,
    });
  }

  onChangeValue() {
    this.onUpdateList.emit(this.list);
  }
}
